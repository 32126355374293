/* eslint-disable @typescript-eslint/no-explicit-any */
import { selectCommonCustomStylesLarge } from '@brands/Utils/customStyles';
import { genderIdentity } from '@brands/Utils/selectButtonOptions';
import React from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { dateAutoSlashes } from '../../Utils/checkAndSetDate';
import { stateOptions } from '../../Utils/selectOptions';
import SelectInput from '../Inputs/SelectInput/SelectInput';
import TextField from '../Inputs/TextField/TextField';
import styles from './styles.module.scss';

interface InsuranceThroughProps {
  register: UseFormRegister<any>;
  errors: Partial<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
}

const InsuranceThrough: React.FC<InsuranceThroughProps> = ({ register, errors, getValues, setValue }) => {
  return (
    <div className={styles.insuranceThroughContainer}>
      <div className={`row ${styles.dashboardRow} p-row`}>
        <div className={`col-5 ${styles.myProfileCol5} ${styles.iSmall}`}>
          <div className="col-md-12">
            <TextField
              {...register('iFirstName')}
              errors={errors}
              name="iFirstName"
              placeholder="Insurance Holder First Name *"
              value={getValues('iFirstName')}
              onChange={(e) => {
                setValue('iFirstName', e.target.value, { shouldValidate: true });
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              {...register('iMiddleName')}
              errors={errors}
              name="iMiddleName"
              placeholder="Insurance Holder Middle Name"
              value={getValues('iMiddleName')}
              onChange={(e) => {
                setValue('iMiddleName', e.target.value, { shouldValidate: true });
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              {...register('iLastName')}
              errors={errors}
              name="iLastName"
              placeholder="Insurance Holder Last Name *"
              value={getValues('iLastName')}
              onChange={(e) => {
                setValue('iLastName', e.target.value, { shouldValidate: true });
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              {...register('iDateOfBirth')}
              errors={errors}
              name="iDateOfBirth"
              placeholder="Insurance Holder Date of Birth *"
              value={getValues('iDateOfBirth')}
              onChange={(e) => dateAutoSlashes(e, 'iDateOfBirth', setValue)}
              enableCursorPos={false}
              maxLength={10}
            />
          </div>
          <div className="col-md-12">
            <SelectInput
              {...register('iGenderIdentity')}
              name="iGenderIdentity"
              options={genderIdentity}
              containerClass={styles.inputWrapper}
              placeholder="Insurance Holder Gender Identity"
              selectorClass={styles.selector}
              style={selectCommonCustomStylesLarge}
              errors={errors}
              selectedValue={getValues('iGenderIdentity')}
              onChange={(value) => {
                if (value) {
                  setValue('iGenderIdentity', value.value, { shouldValidate: true });
                }
              }}
            />
          </div>
        </div>
        <div className={`col-5 ${styles.myProfileCol5} ${styles.iSmallM}`}>
          <div className="col-md-12">
            <TextField
              {...register('iAddress1')}
              errors={errors}
              name="iAddress1"
              placeholder="Insurance Holder Address *"
              value={getValues('iAddress1')}
              onChange={(e) => {
                setValue('iAddress1', e.target.value, { shouldValidate: true });
              }}
            />
          </div>
          <div className="col-md-12">
            <TextField
              {...register('iAddress2')}
              errors={errors}
              name="iAddress2"
              placeholder="Suite, Unit, Floor, etc."
              value={getValues('iAddress2')}
              onChange={(e) => {
                setValue('iAddress2', e.target.value, { shouldValidate: true });
              }}
            />
          </div>
          <div className="col-12" style={{ display: 'inline-flex' }}>
            <div className={`col-md-5 ${styles.colMd5} ${styles.iSmall}`} style={{ marginRight: 'auto' }}>
              <TextField
                {...register('iZipCode')}
                errors={errors}
                name="iZipCode"
                placeholder="Zip Code *"
                value={getValues('iZipCode')}
                onChange={(e) => {
                  setValue('iZipCode', e.target.value, { shouldValidate: true });
                }}
              />
            </div>
            <div className={`col-md-5 ${styles.iSmall}`}>
              <SelectInput
                {...register('iState')}
                name="iState"
                placeholder="State *"
                options={stateOptions}
                errors={errors}
                selectedValue={getValues('iState')}
                onChange={(value) => {
                  if (value) {
                    setValue('iState', value.value, { shouldValidate: true });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceThrough;
