import Button from '@brands/Components/Button/Button';
import { ObjectType } from '@brands/services/careCoordinationReport/types/types';
import { formatDate } from '@brands/Utils/formatDate';
import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { ICase } from '../../../../services/cases/types/ICase';
import { UserProfile } from '../../../../services/identity/types/UserProfile';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type CareCoordinationFindingsSectionxProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  saveObjectType: (note: string, objectType: ObjectType) => Promise<void>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const CareCoordinationFindingsSectionx = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  saveObjectType,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: CareCoordinationFindingsSectionxProps): JSX.Element => {
  const saveCareCoordination = (caseId: number, note: string, storageName: string): void => {
    const notes = JSON.parse(localStorage.getItem(storageName) || '{}');
    notes[caseId] = note;
    localStorage.setItem(storageName, JSON.stringify(notes));
  };

  const handleChangeCareCoordination = (event: React.ChangeEvent<HTMLTextAreaElement>, storageName: string): void => {
    const caseId = thisCase.id;
    const note = event.target.value;
    saveCareCoordination(caseId, note, storageName);
  };

  const submitCareCoordination = (note: string, objectType: ObjectType): void => {
    saveObjectType(note, objectType);
  };

  return (
    <>
      <div className={styles.caseNotes}>
        <div className={styles.caseNotesTitle}>
          <span>Benefits Navigation</span>
        </div>
        {thisCase?.care_coordination_report?.benefits_navigations &&
          thisCase?.care_coordination_report?.benefits_navigations.length > 0 &&
          thisCase?.care_coordination_report?.benefits_navigations
            .sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              // Check for invalid date strings
              if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                return 0;
              }

              return dateA.getTime() - dateB.getTime();
            })
            .map((note) => (
              <div className={styles.caseNotesContent} key={note.id}>
                <div className={styles.caseNotesContentMeta}>
                  <b>{note.created_by.full_name} </b>
                  <b> (Care Advisor) </b>
                  {note.updated_at === null ? formatDate(note.created_at) : formatDate(note.updated_at as string)}
                  <br />
                </div>
                <span className={styles.caseNotesContentText}>{note.benefits_navigation}</span>
                <hr className={styles.ohrHr} />
              </div>
            ))}
      </div>
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') !== 'readOnly' && (
        <>
          <textarea
            {...register('benefits_navigation')}
            readOnly={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') === 'readOnly'}
            className={styles.caseNotesInput}
            placeholder="Add benefits navigation notes here"
            value={consultationForm.benefits_navigation}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                benefits_navigation: e.target.value,
              });
              setValue('benefits_navigation', e.target.value);
              handleChangeCareCoordination(e, 'benefits_navigation');
              if (errors?.benefits_navigation) {
                trigger('benefits_navigation');
              }
            }}
          />

          <Button
            type="button"
            className={`${styles.proviverListBtn} ${styles.assignAprovider} ${styles.caseNotesButton}`}
            onClick={() =>
              submitCareCoordination(consultationForm.benefits_navigation as string, 'benefits_navigation')
            }
            disabled={consultationForm?.benefits_navigation?.trim() === ''}
          >
            Save Benefits Navigation
          </Button>
        </>
      )}
      <div className={styles.caseNotes}>
        <div className={styles.caseNotesTitle}>
          <span>Care Coordination</span>
        </div>
        {thisCase?.care_coordination_report?.notes &&
          thisCase?.care_coordination_report?.notes.length > 0 &&
          thisCase?.care_coordination_report?.notes
            .sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              // Check for invalid date strings
              if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                return 0;
              }

              return dateA.getTime() - dateB.getTime();
            })
            .map((note) => (
              <div className={styles.caseNotesContent} key={note.id}>
                <div className={styles.caseNotesContentMeta}>
                  <b>{note.created_by.full_name} </b>
                  <b> (Care Advisor) </b>
                  {note.updated_at === null ? formatDate(note.created_at) : formatDate(note.updated_at as string)}
                  <br />
                </div>
                <span className={styles.caseNotesContentText}>{note.note}</span>
                <hr className={styles.ohrHr} />
              </div>
            ))}
      </div>
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') !== 'readOnly' && (
        <>
          <textarea
            {...register('note')}
            readOnly={checkCollapsibleHeaderColor(thisCase || '', userInfo, 'careCoordination') === 'readOnly'}
            className={styles.caseNotesInput}
            placeholder="Add care coordination notes here"
            value={consultationForm.note}
            onChange={(e) => {
              setConsultationForm({
                ...consultationForm,
                note: e.target.value,
              });
              setValue('note', e.target.value);
              handleChangeCareCoordination(e, 'note');
              if (errors?.note) {
                trigger('note');
              }
            }}
          />

          <Button
            type="button"
            className={`${styles.proviverListBtn} ${styles.assignAprovider} ${styles.caseNotesButton}`}
            onClick={() => submitCareCoordination(consultationForm.note as string, 'note')}
            disabled={consultationForm?.note?.trim() === ''}
          >
            Save Care Coordination
          </Button>
        </>
      )}
    </>
  );
};

export default React.memo(CareCoordinationFindingsSectionx);
