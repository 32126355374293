/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { Loading } from '@brands/Components/LoadingSpinner/Loading';
import FileUploadProgressBar from '@brands/Components/UploadProgressBar/FileUploadProgressBar';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { selectAuth } from '@brands/store/selectors/auth';
import { Conversation } from '@twilio/conversations';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IoIosArrowDropleftCircle, IoIosSearch } from 'react-icons/io';
import { IoDownloadOutline } from 'react-icons/io5';
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { useConversationMessages, useIdentityContext } from '../../hooks';
import { ICase } from '../../services/cases/types/ICase';
import { markLastReadMessage } from '../../services/communication/markLastReadMessage';
import { IGMConversation } from '../../services/communication/types/IGMConversation';
import { IGMConversationParticipant } from '../../services/communication/types/IGMConversationParticipant';
import { UserProfile, UserRoleName } from '../../services/identity/types/UserProfile';
import AddParticipantModal from '../ChatModal/AddParticipantModal';
import CaseActiveChatBubble from '../ChatModal/ChatBubble/CaseActiveChatBubble';
import DownloadModal from '../ChatModal/DownloadModal';
import LeaveModal from '../ChatModal/LeaveModal';
import styles from './case-styles.module.scss';
import CaseChatFooter from './CaseChatFooter';
import CaseMessageInfo from './CaseMessageInfo';
import CaseMobileOptionsModal from './CaseMobileOptionsModal';
import { useSelector } from 'react-redux';
import { selectShowVideoThumbnail } from '@brands/store/selectors/showVideoThumbnail';

interface MessageBodyProps {
  conversation: IGMConversation | null;
  twilioConversation: Conversation | null;
  readOnlyConversation: IGMConversation | null;
  isMobile: boolean;
  isProfileCard?: boolean;
  isVideoConversation?: boolean;
  setLeaveModalOpen: (isOpen: boolean) => void;
  leaveModalOpen: boolean;
  conversationSid: string | null;
  setConversationSid: (sid: string | null) => void;
  currentCase: ICase | undefined;
  updateUnreadNotification: () => void;
  conversationType: string | undefined;
  patientCurrentState: string | undefined;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaseMessageBody: React.FC<MessageBodyProps> = ({
  conversation,
  twilioConversation,
  readOnlyConversation,
  isMobile,
  isProfileCard,
  isVideoConversation,
  leaveModalOpen,
  setLeaveModalOpen,
  conversationSid,
  setConversationSid,
  currentCase,
  updateUnreadNotification,
  conversationType,
  patientCurrentState,
  loading,
  setLoading,
}) => {
  const contextValues = useIdentityContext();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(selectAuth);
  const identity = contextValues.userInfo?.id.toString();
  const [search, setSearch] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [isMobileOptionsModalOpen, setIsMobileOptionsModalOpen] = useState(false);
  const messagesParams = useMemo(() => (search ? { search } : {}), [search]);
  const [progress, setProgress] = useState(0);
  const [displayProgressBar, setDisplayProgressBar] = useState(false);
  const [choosenFiles, setChoosenFiles] = useState<File[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState<string>('');
  const showVideoThumbnail = useSelector(selectShowVideoThumbnail);
  const { messages, editMessage, deleteMessage, sendMessage, loadNextPage } = useConversationMessages(
    twilioConversation,
    messagesParams,
    readOnlyConversation,
  );
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [addParticipantModalOpen, setAddParticipantModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setShowDropDown(false) });

  const userRole = useMemo(() => {
    if (!contextValues.userInfo) {
      return null;
    }

    return contextValues.userInfo.role;
  }, [contextValues]);

  const listInnerRef = useRef<HTMLDivElement>(null);

  const throttledSearch = throttle(setSearch, 100, { trailing: true });

  const [loadedNextPage, setLoadedNextPage] = useState(false);

  const getRemoteParticipants = (): IGMConversationParticipant[] | undefined => {
    let remoteParticipants: any[] | undefined = [];

    const participants: UserProfile[] = [];
    const participantsId: number[] = [];

    if (currentCase?.patient) {
      if (currentCase?.patient.guardian_relationship) {
        participantsId.push(currentCase.patient?.guardian_id || 0);
      } else {
        participantsId.push(currentCase.patient.id);
      }
      participants.push(currentCase.patient);
    }
    if (currentCase?.care_advisor) {
      participantsId.push(currentCase.care_advisor.id);
      participants.push(currentCase.care_advisor);
    }
    if (currentCase?.provider) {
      participantsId.push(currentCase.provider.id);
      participants.push(currentCase.provider);
    }

    if (conversation?.participants) {
      remoteParticipants = conversation?.participants?.filter(
        (participant) => +participant.identity !== contextValues.userInfo?.id,
      );
    }

    remoteParticipants = remoteParticipants?.filter((object1) =>
      participants.some((object2) => +object1.identity === object2.id),
    );

    if (
      (conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` ||
        conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`) &&
      currentCase?.patient.guardian_relationship
    ) {
      remoteParticipants.push(
        conversation?.participants?.find(
          (conversationParticipant) => conversationParticipant.role === UserRoleName.Patient,
        ),
      );
    }

    return remoteParticipants;
  };

  const onScroll = useCallback(() => {
    if (!listInnerRef.current) {
      return;
    }
    const { scrollTop } = listInnerRef.current;
    if (scrollTop === 0) {
      setLoadedNextPage(true);
      loadNextPage();
    }
  }, [loadNextPage]);

  useEffect(() => {
    if (isMobile) {
      setEditMode(isInEditMode !== '');
    }
  }, [isInEditMode, setEditMode]);

  const handleNavigateBack = (): void => {
    const currentPath = window.location.pathname;
    const segments = currentPath.split('/');
    const newPath = segments.slice(0, segments.length - 1).join('/');
    navigate(newPath);
    setConversationSid(null);
  };

  const addParticipant = (): void => {
    setAddParticipantModalOpen(true);
  };

  const scrollDown = (): void => {
    if (listInnerRef.current) {
      const lastLi = listInnerRef.current.lastElementChild;
      if (lastLi) {
        const { scrollHeight } = listInnerRef.current;
        const { offsetHeight } = listInnerRef.current;
        const lastLiHeight = lastLi.clientHeight;
        listInnerRef.current.scrollTop = scrollHeight - offsetHeight + lastLiHeight;
      }
    }
  };

  const scrollTothis = (number: number, index: number): void => {
    if (index === messages.length - 1 && listInnerRef.current) {
      listInnerRef.current.scrollTop = number;
    }
  };

  const handleMediaLoad = (index: number): void => {
    if ((index === messages.length - 1 || index >= messages.length - 10) && messages[index].media_ids.length > 0) {
      scrollDown();
    }
  };
  useEffect(() => {
    if (!loadedNextPage) {
      scrollDown();
    }
    setLoadedNextPage(false);
  }, [messages, listInnerRef]);

  useEffect(() => {
    scrollDown();
  }, []);
  const markAsRead = async (): Promise<void> => {
    if (conversationSid && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      const checkParticipantLastReadMessage = conversation?.participants?.find(
        (participantSingle) => Number(participantSingle.identity) === Number(contextValues.userInfo?.id),
      );
      const lastReadMessageIndex = checkParticipantLastReadMessage?.last_read_message_index;
      if (
        (lastReadMessageIndex === undefined ||
          lastReadMessageIndex === null ||
          lastReadMessageIndex < lastMessage.index) &&
        (conversation?.state === 'active' || conversation?.state === 'inactive') &&
        lastMessage &&
        lastMessage.index !== lastReadMessageIndex &&
        Number(lastMessage.created_by) !== Number(identity) &&
        userRole?.name !== UserRoleName.Admin &&
        userRole?.name !== UserRoleName.SuperAdmin &&
        userRole?.name !== UserRoleName.OrganizationAdmin
      ) {
        await markLastReadMessage(conversationSid, lastMessage.sid);
        updateUnreadNotification();
      }
    }
  };

  useEffect(() => {
    markAsRead();
  }, [messages[messages.length - 1], conversationSid]);

  return (
    <>
      <div
        className={styles.chatBody}
        style={{
          height: isVideoConversation ? 'calc(100% - 50px)' : '',
          width: isVideoConversation || isProfileCard ? '100%' : '',
          margin: isVideoConversation ? '0' : '',
          display: conversationSid || conversation?.sid ? 'flex' : 'none',
        }}
      >
        <div
          className={`${styles.messageSearch} ${showVideoThumbnail && styles.addMarginTop}`}
          style={{ display: isProfileCard ? 'none' : 'flex' }}
        >
          {isMobile && (
            <span className={styles.backArrow} onClick={handleNavigateBack}>
              <IoIosArrowDropleftCircle />
            </span>
          )}
          <div className={styles.verticalRow} />
          {isMobile ? (
            <div className={`${styles.participantDiv} ${styles.participantMessageDivBody} ${styles.mobileHeaderInfo}`}>
              <CaseMessageInfo
                currentCase={currentCase}
                item={conversation}
                isSelected
                conversationType={conversationType!}
                isMobile
              />
            </div>
          ) : (
            <div className={styles.searchBox}>
              <input
                type="text"
                className={styles.inputSearch}
                placeholder="Search"
                onChange={(e) => {
                  setQuery(e.target.value);
                  throttledSearch(e.target.value);
                }}
                value={query}
              />
              <button aria-label="Search" type="button" className={styles.btnSearch} onClick={() => setSearch(query)}>
                <IoIosSearch className={styles.searchIcon} />
              </button>
            </div>
          )}
          <div ref={ref} style={{ position: 'relative' }}>
            <BsThreeDotsVertical
              id="options"
              className={styles.options}
              onClick={(): void => (!isMobile ? setShowDropDown(!showDropDown) : setIsMobileOptionsModalOpen(true))}
            />
            {showDropDown && (
              <div className={styles.dropDown}>
                {false && (userRole?.name === UserRoleName.Provider || userRole?.name === UserRoleName.CareAdvisor) && (
                  <div onClick={() => addParticipant()} className={styles.borderBottom}>
                    Add Participant
                    <MdOutlinePersonAddAlt className={styles.dropDownIcon} />
                  </div>
                )}
                <div onClick={() => setDownloadModalOpen(true)}>
                  Download Chat
                  <IoDownloadOutline className={styles.dropDownIcon} />
                </div>
              </div>
            )}
          </div>
          {leaveModalOpen && <LeaveModal setOpenModal={setLeaveModalOpen} conversationSid={conversation?.sid} />}
          {downloadModalOpen && (
            <DownloadModal
              setOpenModal={setDownloadModalOpen}
              conversationSid={conversation?.sid}
              setLoading={setLoading}
            />
          )}
        </div>
        {loading ? (
          <Loading dotted />
        ) : (
          <>
            {displayProgressBar && (
              <div className={styles.progressBar}>
                {displayProgressBar && <FileUploadProgressBar progress={progress} />}
              </div>
            )}
            <div
              className={`${styles.popupMessageContainer} w-100 
              ${showVideoThumbnail ? styles.adjustHeight : styles.keepCurrentHeight}
              ${isMobile && editMode && styles.forceEditPopupMessageContainer}
              ${
                (userInfo.role.name === UserRoleName.CareAdvisor || userInfo.role.name === UserRoleName.Provider) &&
                conversationType &&
                (conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}` ||
                  conversationType === `${UserRoleName.Patient}_${UserRoleName.Provider}`) &&
                styles.mobileExtraAdjust
              }`}
              onScroll={onScroll}
              ref={listInnerRef}
            >
              <ul className="h-100">
                {messages
                  .sort((a, b) => a.index - b.index)
                  .map((message, index) => (
                    <CaseActiveChatBubble
                      messageCreator={
                        getRemoteParticipants()?.find(
                          (activeParticipant) => Number(activeParticipant?.identity) === Number(message.created_by),
                        )?.role === UserRoleName.Patient
                          ? currentCase?.patient
                          : getRemoteParticipants()?.find(
                              (activeParticipant) => Number(activeParticipant?.identity) === Number(message.created_by),
                            )?.role === UserRoleName.Provider
                          ? currentCase?.provider
                          : getRemoteParticipants()?.find(
                              (activeParticipant) => Number(activeParticipant?.identity) === Number(message.created_by),
                            )?.role === UserRoleName.CareAdvisor
                          ? currentCase?.care_advisor
                          : undefined
                      }
                      messageIndex={index}
                      handleMediaLoad={handleMediaLoad}
                      conversationState={conversation?.state}
                      direction={Number(message.created_by) === Number(identity) ? 'outgoing' : 'incoming'}
                      messageDetails={{
                        id: message.sid,
                        authorRelation: currentCase?.patient?.guardian_relationship,
                        dependentName: currentCase?.patient.full_name,
                      }}
                      participants={conversation?.participants}
                      message={message}
                      editMessage={editMessage}
                      deleteMessage={deleteMessage}
                      isVCRoom={isVideoConversation}
                      listInnerRef={listInnerRef}
                      scrollTothis={scrollTothis}
                      patientCurrentState={patientCurrentState}
                      setEditMode={setEditMode}
                      isInEditMode={isInEditMode}
                      setIsInEditMode={setIsInEditMode}
                    />
                  ))}
                <li>
                  {conversation?.participants?.filter((participant) => participant.role === UserRoleName.Patient) &&
                    conversation?.participants.length === 1 && (
                      <div className={styles.systemMessage}>
                        <div className={styles.systemPopupMessageContainer}>
                          <div className={styles.popupMessage}>
                            <p className="m-0 m-4">A Care Advisor will reach out to you soon</p>
                          </div>
                        </div>
                      </div>
                    )}
                </li>
              </ul>
            </div>
            {!editMode &&
              conversation?.participants?.find((me) => Number(me.identity) === contextValues.userInfo?.id)?.status ===
                'active' &&
              ((conversation?.state === 'active' ||
                (conversation?.state === 'inactive' &&
                  (userInfo.role.name === UserRoleName.Patient || userInfo.role.name === UserRoleName.CareAdvisor) &&
                  conversationType === `${UserRoleName.Patient}_${UserRoleName.CareAdvisor}`) ||
                ((userInfo.role.name === UserRoleName.Provider || userInfo.role.name === UserRoleName.CareAdvisor) &&
                  conversationType === `${UserRoleName.Provider}_${UserRoleName.CareAdvisor}` &&
                  conversation?.state !== 'closed')) &&
              conversation?.participants?.find((me) => Number(me.identity) === contextValues.userInfo?.id)?.status ===
                'active' ? (
                <div className={styles.chatFooter}>
                  <CaseChatFooter
                    conversationSid={conversation?.sid}
                    sendMessage={sendMessage}
                    choosenFiles={choosenFiles}
                    setChoosenFiles={setChoosenFiles}
                    setDisplayProgressBar={setDisplayProgressBar}
                    displayProgressBar={displayProgressBar}
                    setProgress={setProgress}
                    conversationType={conversationType}
                    caseType={currentCase?.type || ''}
                  />
                </div>
              ) : (
                !editMode &&
                conversation?.state !== 'active' && (
                  <div className={`${styles.chatFooter} ${styles.messagesFooter}`}>
                    <span>This conversation is now inactive.</span>
                  </div>
                )
              ))}
          </>
        )}
      </div>
      {addParticipantModalOpen && (
        <AddParticipantModal setOpenModal={setAddParticipantModalOpen} conversationSid={conversation?.sid ?? ''} />
      )}
      {isMobile && isMobileOptionsModalOpen && (
        <CaseMobileOptionsModal
          setOpenModal={setIsMobileOptionsModalOpen}
          conversation={conversation}
          isProfileCard={isProfileCard}
          leaveModalOpen={leaveModalOpen}
          setLeaveModalOpen={setLeaveModalOpen}
          query={query}
          setQuery={setQuery}
          setSearch={setSearch}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

CaseMessageBody.defaultProps = {
  isVideoConversation: false,
  isProfileCard: false,
};

export default CaseMessageBody;
