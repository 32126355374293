import Container from '@brands/Components/Container/Container';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import useWindowSize from '@brands/hooks/useWindowSize';
import React, { FC } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import styles from './styles.module.scss';

const WashingtonMyHealthMyDataPrivacyNotice: FC = () => {
  const screenSize = useWindowSize();
  return (
    <>
      <Sidenav isVisibleOnMobile hideDefaultOptions authLoog>
        <a
          href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
          target="_blank"
          className={styles.sidenavContainer}
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <div className={styles.sidenavToggleText}>Help</div>
          <div className={styles.sidenavToggle}>
            <AiOutlineQuestionCircle />
          </div>
        </a>
      </Sidenav>
      <Container
        isBGEclipse={false}
        patientDashboard
        childClassName={styles.dashboard}
        containerStyle={{
          justifyContent: screenSize.width >= 1289 ? 'center' : 'start',
          overflowY: screenSize.width >= 1289 ? 'hidden' : 'scroll',
        }}
      >
        <div className={styles.tOSBody}>
          <div id="content" className={styles.tOSBodySiteContent}>
            <main id="primary" className="site-main">
              <article id="post-889" className="post-889 page type-page status-publish hentry">
                <div className={styles.entryContent}>
                  <h2 style={{ marginBottom: '20px', textAlign: 'left' }}>
                    <strong>WASHINGTON CONSUMER HEALTH DATA PRIVACY POLICY</strong>
                  </h2>
                  <span style={{ marginBottom: '15px', display: 'block' }}>Revision date: [10/28/2024]</span>
                  <p>
                    This Washington Consumer Health Data Privacy Policy (the “Consumer Health Data Policy”) supplements
                    Dentistry.One (“D1”, “we”, “us” or “our”) general{' '}
                    <a style={{ color: '#2f64c1' }} href="https://dentistry.one/privacy-policy/">
                      Privacy Policy
                    </a>{' '}
                    and describes our data collection, processing and sharing practices regarding personal information
                    defined as “consumer health data” subject to the Washington My Health My Data Act.
                  </p>

                  <p>
                    Put more simply, this Consumer Health Data Policy describes our general data practices relating to
                    information that identifies the past, present, or future physical or mental health status of
                    individuals who are state of Washington residents or whose health data is collected in the state of
                    Washington. Note that this Consumer Health Data Policy does not cover or discuss exempted health
                    data that is subject to other laws, like the federal Health Insurance Portability and Accountability
                    Act or data that is used for scientific, historical, or statistical research purposes.
                  </p>

                  <p>Please review this Consumer Health Data Policy carefully. In this Policy, we explain:</p>

                  <ul className={styles.dotListWashington}>
                    <li>
                      ●{' '}
                      <a href="#consumer-health-data-we-collect">
                        <span>The Consumer Health Data That We Collect</span>
                      </a>
                    </li>
                    <li>
                      ●{' '}
                      <a href="#why-we-collect-health-data">
                        <span>Why We Collect Consumer Health Data</span>
                      </a>
                    </li>
                    <li>
                      ●{' '}
                      <a href="#how-we-collect-health-data">
                        <span>How Do We Collect Consumer Health Data</span>
                      </a>
                    </li>
                    <li>
                      ●{' '}
                      <a href="#shared-health-data">
                        <span>What Consumer Health Data Is Shared and To Whom</span>
                      </a>
                    </li>
                    <li>
                      ●{' '}
                      <a href="#your-rights">
                        <span>Your Rights And How To Exercise Them</span>
                      </a>
                    </li>
                  </ul>

                  <p>If you have any questions, please contact us at: privacy@dentistry.one.</p>

                  <section id="consumer-health-data-we-collect" className={styles.section}>
                    <h3>The Consumer Health Data That We Collect:</h3>
                    <p>The types of consumer health data that we collect are:</p>
                    <ul className={styles.dotListWashingtonSection}>
                      <li>
                        ● <span>Individual health conditions, treatment, diseases, or diagnosis;</span>
                      </li>
                      <li>
                        ● <span>Health-related surgeries or procedures;</span>
                      </li>
                      <li>
                        ● <span>Use or purchase of prescribed medication;</span>
                      </li>
                      <li>
                        ● <span>Bodily functions, vital signs, symptoms, or measurements;</span>
                      </li>
                      <li>
                        ● <span>Diagnoses or diagnostic testing, treatment, or medication; and</span>
                      </li>
                      <li>
                        ● <span>Other health-related information that you choose to provide to us.</span>
                      </li>
                    </ul>
                    <p>
                      We also collect information that is used to identify you as someone who is seeking health care
                      services. This information includes:
                    </p>
                    <ul className={styles.dotListWashingtonSection}>
                      <li>
                        ●{' '}
                        <span>
                          Personal Identifiers, such as your name, address, contact information and login and password
                          information; and
                        </span>
                      </li>
                      <li>
                        ●{' '}
                        <span>
                          Online Identifiers, such as unique personal identifiers, device IDs, ad IDs, IP addresses, and
                          cookie data that are gathered as your browse or access our websites and portals.
                        </span>
                      </li>
                    </ul>
                  </section>

                  <section id="why-we-collect-health-data" className={styles.section}>
                    <h3>Why We Collect Consumer Health Data:</h3>
                    <p>We may collect consumer health data in order to:</p>
                    <ul className={styles.dotListWashingtonSection}>
                      <li>
                        ● <span>Provide the products or services that you have requested from us;</span>
                      </li>
                      <li>
                        ● <span>Manage, administer and secure your personal account with us;</span>
                      </li>
                      <li>
                        ● <span>Respond to your questions and inquiries;</span>
                      </li>
                      <li>
                        ● <span>Perform data analytics, market research and segmentation;</span>
                      </li>
                      <li>
                        ● <span>Market our products and services;</span>
                      </li>
                      <li>
                        ●{' '}
                        <span>
                          Enhance our products and services, including developing new products and services; and
                        </span>
                      </li>
                      <li>
                        ● <span>Comply with law.</span>
                      </li>
                    </ul>
                    <p>
                      In addition, your consumer health data may be used for internal purposes required for the proper
                      and lawful administration and operation of our business, such as record keeping, auditing,
                      managing our operations and investigating, defending and prosecuting legal claims.
                    </p>
                  </section>

                  <section id="how-we-collect-health-data" className={styles.section}>
                    <h3>How Do We Collect Consumer Health Data:</h3>
                    <p>We collect consumer health data from:</p>
                    <ul className={styles.dotListWashingtonSection}>
                      <li>
                        ● <span>You, directly;</span>
                      </li>
                      <li>
                        ● <span>Our affiliates; and</span>
                      </li>
                      <li>
                        ● <span>Our vendors and service providers.</span>
                      </li>
                    </ul>
                  </section>

                  <section id="shared-health-data" className={styles.section}>
                    <h3>What Consumer Health Data Is Shared and To Whom:</h3>
                    <p>
                      As a general rule, we only disclose your consumer health information to another entity to receive
                      goods or services consistent with the purposes as disclosed to you in this Consumer Health Data
                      Policy, such as to our vendors or processors. However, there may be times where we may share some
                      or all of the categories of consumer health information described in this Consumer Health Data
                      Policy for different reasons, as described below:
                    </p>
                    <ul className={styles.dotListWashingtonList}>
                      <li>
                        <span className={styles.dot}>●</span>
                        <span>As required by law or for legal purposes.</span> We may disclose some or all of your
                        consumer health data to courts, litigants, regulators, arbitrators, administrative bodies or law
                        enforcement when we have reason to believe that disclosing this information is necessary to
                        comply with law or resolve actual or suspected claims. We may also disclose your consumer health
                        data to identify, contact or bring legal action against someone who may be violating any
                        agreement with us, or may be causing injury to or interference with (either intentionally or
                        unintentionally) our rights or property, or anyone else that could be harmed by such activities.
                        We may disclose information in response to a subpoena, search warrant, in connection with
                        judicial proceedings, or pursuant to court orders, legal process or other law enforcement
                        measures.
                      </li>
                      <li>
                        <span className={styles.dot}>●</span>
                        <span>To our Affiliates.</span> We may disclose all or part of your consumer health data to our
                        affiliates in the course of our business operations. The affiliates that may receive your
                        consumer health data is MouthWatch LLC.
                      </li>
                      <li>
                        <span className={styles.dot}>●</span>
                        <span>To individuals or entities you authorize.</span> We may disclose your personal information
                        to individuals or entities at your direction.
                      </li>
                      <li>
                        <span className={styles.dot}>●</span>
                        <span>In corporate transactions.</span> We may share all or part of your personal information
                        with other entities in connection with the sale, assignment, merger or other transfer of all or
                        a portion of our organization or assets to such entities (including due to a sale in connection
                        with a bankruptcy).
                      </li>
                    </ul>
                  </section>

                  <section id="your-rights" className={styles.section}>
                    <h2>Your Rights And How To Exercise Them:</h2>
                    <p>
                      Subject to the restrictions in the Washington My Health My Data Act, if you are a Washington state
                      resident or someone whose consumer health data has been collected in Washington state, you may
                      have certain rights with regards to this data. These rights are:
                    </p>
                    <ul className={styles.dotListWashingtonSection}>
                      <li>
                        ●{' '}
                        <span>
                          The right to know whether D1 is collecting, sharing, or selling consumer health data
                          concerning you;
                        </span>
                      </li>
                      <li>
                        ● <span>The right to access consumer health data concerning you held by D1;</span>
                      </li>
                      <li>
                        ●{' '}
                        <span>
                          The right to be informed of all third parties and D1 affiliates with whom we have shared or
                          sold the consumer health data and how to contact these entities (although, we do not “sell”
                          your consumer health data;
                        </span>
                      </li>
                      <li>
                        ●{' '}
                        <span>
                          The right to withdraw your consent, if previously given, to our collection and sharing of
                          consumer health data concerning you; and
                        </span>
                      </li>
                      <li>
                        ●{' '}
                        <span>The right to request that D1 delete consumer health data concerning you held by us.</span>
                      </li>
                    </ul>
                  </section>

                  <p>
                    Please note that the terms “share” and “sell” have special definitions under Washington My Health My
                    Data Act, which will impact the scope of your rights under this law.
                  </p>

                  <p>
                    You can submit a request to exercise your rights by{' '}
                    <a
                      style={{ color: '#2f64c1' }}
                      data-element-id="21R"
                      title="mailto:privacy@dentistry.one"
                      href="mailto:privacy@dentistry.one"
                      target=""
                      rel="noopener noreferrer nofollow"
                      id="pdfjs_internal_id_21R"
                    >
                      privacy@dentistry.one
                    </a>
                    .
                  </p>
                  <p>
                    We will evaluate your request and respond to you. We reserve the right, as allowed by the Washington
                    My Health My Data Act, to authenticate your identity before complying with your request. If your
                    request to exercise a right is denied, you may appeal the denial by responding through same manner
                    through which we communicated the denial. If your appeal is unsuccessful, you can contact the
                    Washington State Attorney General at{' '}
                    <a
                      style={{ color: '#2f64c1' }}
                      data-element-id="22R"
                      title="https://www.atg.wa.gov/file-complaint"
                      href="https://www.atg.wa.gov/file-complaint"
                      target=""
                      rel="noopener noreferrer nofollow"
                      id="pdfjs_internal_id_22R"
                    >
                      https://www.atg.wa.gov/file-complaint
                    </a>{' '}
                    to raise an issue or submit a complaint.
                  </p>
                </div>
              </article>
            </main>
          </div>
        </div>
      </Container>
    </>
  );
};

export default WashingtonMyHealthMyDataPrivacyNotice;
