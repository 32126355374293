/* eslint-disable react/no-array-index-key */
import { selectAuth } from '@brands/store/selectors/auth';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';

import { useAppSelector } from '../../hooks/useReduxHook';
import { INotification } from '../../services/notification/types/INotification';
import { formatDate } from '../../Utils/formatDate';
import { replaceTemplate } from '../../Utils/replaceTemplate';
import styles from './styles.module.scss';
import { isNotificationClickable } from './utils/notification';

type NotificationProps = {
  notifications: INotification[];
  onNotification: (notification: INotification) => void;
  loadNextPage: () => Promise<void>;
  dataLoading: boolean;
};

const Notifications = ({
  notifications,
  onNotification,
  loadNextPage,
  dataLoading,
}: NotificationProps): JSX.Element | null => {
  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useRef<HTMLDivElement>(null);

  const { userInfo } = useAppSelector(selectAuth);
  const [, setRefresh] = useState(0);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]): void => {
      const lastItem = entries[0];
      if (lastItem.isIntersecting && !dataLoading) {
        loadNextPage();
      }
    },
    [loadNextPage, dataLoading],
  );

  const displayMinutesInEvery5Minutes = (minutes: number): number => {
    return Math.floor(minutes / 5) * 5;
  };

  const formatNotificationTime = (created_at: string): string => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + now.getTimezoneOffset());

    const createdDate = new Date(created_at);
    const diffInSeconds = Math.floor((now.getTime() - createdDate.getTime()) / 1000);

    if (diffInSeconds < 60 * 5) return 'Now';
    if (diffInSeconds < 60 * 60) return `${displayMinutesInEvery5Minutes(Math.floor(diffInSeconds / 60))}m`;
    if (diffInSeconds < 60 * 60 * 24) return `${Math.floor(diffInSeconds / (60 * 60))}h`;
    if (diffInSeconds < 60 * 60 * 24 * 7) return `${Math.floor(diffInSeconds / (60 * 60 * 24))}d`;
    if (diffInSeconds < 60 * 60 * 24 * 30) return `${Math.floor(diffInSeconds / (60 * 60 * 24 * 7))}w`;
    if (diffInSeconds < 60 * 60 * 24 * 365) return `${Math.floor(diffInSeconds / (60 * 60 * 24 * 30))}mo`;
    return `${Math.floor(diffInSeconds / (60 * 60 * 24 * 365))}y`;
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastItemRef.current) observer.current.observe(lastItemRef.current);
  }, [handleIntersection]);

  useEffect(() => {
    // Set up an interval to trigger a re-render every 5 minutes
    setRefresh((prevRefresh) => prevRefresh + 1);
    const interval = setInterval(() => {
      setRefresh((prevRefresh) => prevRefresh + 1);
    }, 5 * 60 * 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.sidenavBody}>
      <div className={styles.sidenavNotificationInfo}>
        <div className={styles.sidenavNotificationHeader}>Notifications</div>
      </div>
      <div className={styles.sidenavNotificationInfo}>
        <ul>
          {notifications.map((notification) => {
            const templatePayload: Record<string, string | number> = {};

            if (notification.payload?.date_time) {
              templatePayload.date_time = moment.utc(notification.payload?.date_time).isValid()
                ? formatDate(notification.payload?.date_time, true)
                : notification.payload?.date_time;
            }

            return (
              <li key={notification.id}>
                <div
                  className={cn(styles.notificationBody, {
                    [styles.clickable]: !(
                      notification.acknowledged_at && !isNotificationClickable(notification, userInfo)
                    ),
                  })}
                  onClick={() => onNotification(notification)}
                >
                  <div className={styles.icon}>
                    <HiInformationCircle />
                    <div className={styles.text}>{formatNotificationTime(notification?.created_at || '')}</div>
                  </div>
                  <div className={styles.text}>
                    {replaceTemplate(notification.body || '', templatePayload)
                      .split('<br>')
                      .map((word, index) => (
                        <div key={index}>{word}</div>
                      ))}
                  </div>
                  {!notification.acknowledged_at && <div className={styles.blueDot} />}
                </div>
              </li>
            );
          })}
          <div ref={lastItemRef} />
        </ul>
      </div>
    </div>
  );
};

export default Notifications;
