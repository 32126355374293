import styles from './styles.module.scss';
import React, { useState } from 'react';
import patientEducationImage1 from './patientEducationSVG1.svg';
import patientEducationImage2 from './patientEducationSVG2.svg';
import patientEducationImage3 from './patientEducationSVG3.svg';
const PatientEducation = (): JSX.Element => {
  return (
    <div className={styles.patientEducationDiv}>
      <div className={styles.patientEducationTitle}>
        <span>Patient Education</span>
      </div>
      <div className={styles.patientEducationCards}>
        <div className={styles.patientEducationCard}>
          <div className={styles.patientEducationCardImage}>
            <img src={patientEducationImage1} />
          </div>
          <div className={styles.patientEducationCardText}>
            <span>Strong Oral Hygiene Habits for Healthier Teeth and Gums </span>
          </div>
          <div className={styles.patientEducationCardAuthor}>
            <span>
              Effective oral hygiene goes beyond brushing. Regular checkups, fluoride use, mindful eating, and avoiding
              tobacco are key to keeping teeth strong, gums healthy, and breath fresh. Developing a consistent routine
              can protect your smile and improve overall well-being.
            </span>
          </div>
          <div>
            <a
              className={styles.patientEducationCardLink}
              target="_blank"
              href="https://www.patienthealthcenter.com/mgkOQvgp/pages/oral-hygiene"
            >
              Read More
            </a>
          </div>
        </div>

        <div className={styles.patientEducationCard}>
          <div className={styles.patientEducationCardImage}>
            <img src={patientEducationImage2} />
          </div>
          <div className={styles.patientEducationCardText}>
            <span>Preventing Tooth Decay and Maintaining Lifelong Oral Health </span>
          </div>
          <div className={styles.patientEducationCardAuthor}>
            <span>
              Tooth decay doesn’t have to be inevitable. With a combination of proper oral hygiene, balanced diet, and
              preventive treatments like fluoride and sealants, you can protect your teeth and reduce decay risk for
              yourself and loved ones.
            </span>
          </div>
          <div>
            <a
              className={styles.patientEducationCardLink}
              target="_blank"
              href="https://www.patienthealthcenter.com/mgkOQvgp/pages/tooth-decay-prevention"
            >
              Read More
            </a>
          </div>
        </div>

        <div className={styles.patientEducationCard}>
          <div className={styles.patientEducationCardImage}>
            <img src={patientEducationImage3} />
          </div>
          <div className={styles.patientEducationCardText}>
            <span>Quick Actions for Tooth Injuries, Pain, and Gum Health </span>
          </div>
          <div className={styles.patientEducationCardAuthor}>
            <span>
              From knocked-out teeth to severe pain, knowing how to respond to dental emergencies can make all the
              difference. Immediate steps, like rinsing with warm water or applying a cold compress, help protect teeth
              and gums until professional care is available.
            </span>
          </div>
          <div>
            <a
              className={styles.patientEducationCardLink}
              target="_blank"
              href="https://www.patienthealthcenter.com/mgkOQvgp/pages/dental-emergencies"
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PatientEducation;
