/* eslint-disable max-len */
import ExsistAccount from '@brands/Auth/SignIn/ExistingAccount';
import SignIn from '@brands/Auth/SignIn/SignIn';
import CompleteSignUp from '@brands/Auth/SignUp/CompleteSignUp';
import ConsentPolicy from '@brands/Auth/SignUp/ConsentPolicy';
import NoticeOfPrivacyPractices from '@brands/Auth/SignUp/NoticeOfPrivacyPractices';
import SignUp from '@brands/Auth/SignUp/SignUp';
import TermsOfService from '@brands/Auth/SignUp/TermsOfService';
import WashingtonMyHealthMyDataPrivacyNotice from '@brands/Auth/SignUp/WashingtonMyHealthMyDataPrivacyNotice';
import ZendeskLogout from '@brands/Auth/ZendeskLogout/ZendeskLogout';
import PrivateRoutes from '@brands/Components/PrivateRoutes/PrivateRoutes';
import { VideoProvider } from '@brands/contexts/VideoProvider';
import Appointments from '@brands/Dashboard/Appointments/Appointments';
import AddAdditionalInformation from '@brands/Dashboard/Appointments/Patient/AddAdditionalInformation/AddAdditionalInformationValidation';
import CaseChatWindow from '@brands/Dashboard/CaseChatWindow/CaseChatWindow';
import ConsultationPage from '@brands/Dashboard/ConsultationPage/ConsultationPageValidation';
import OralHealthReportComponent from '@brands/Dashboard/ConsultationPage/OralHealthReport/OralHealthReportValidation';
import SmileScanDisclaimer from '@brands/Dashboard/ConsultationPage/SmileScanReport/SmileScanDisclaimer';
import SmileScanReportComponent from '@brands/Dashboard/ConsultationPage/SmileScanReport/SmileScanReport';
import AdminDashboard from '@brands/Dashboard/Dashboard/AdminDashboard';
import AdminMyProfile from '@brands/Dashboard/Dashboard/AdminMyProfile';
import Dashboard from '@brands/Dashboard/Dashboard/Dashboard';
import ScheduleAvailability from '@brands/Dashboard/Dashboard/ScheduleAvailability';
import InsuranceForm from '@brands/Dashboard/InsuranceForm/InsuranceFormValidation';
import DentalHistory from '@brands/Dashboard/MedicalHistory/DentalHistory/DentalHistoryValidation';
import MedicalCondition from '@brands/Dashboard/MedicalHistory/MedicalCondition/MedicalConditionValidation';
import MedicalHistory from '@brands/Dashboard/MedicalHistory/MedicalHistory/MedicalHistoryValidation';
import MedicalInformationSummary from '@brands/Dashboard/MedicalHistory/MedicalInformationSummary/MedicalInformationSummaryValidation';
import MyProfile from '@brands/Dashboard/MyProfile/Myprofile';
import CreateConsultationFor from '@brands/Dashboard/MyProfile/ReviewProfile/CreateConsultationForValidation';
import ReviewProfile from '@brands/Dashboard/MyProfile/ReviewProfile/ReviewProfile';
import NotFoundPage from '@brands/Dashboard/NotFoundPage/NotFoundPage';
import AdditionalInformation from '@brands/Dashboard/PatientForms/PatientForm/AdditionalInformation/AdditionalInformationValidation';
import CreateYourProfile from '@brands/Dashboard/PatientForms/PatientForm/CreateYourProfile/CreateYourProfileValidation';
import DescribePainLevel from '@brands/Dashboard/PatientForms/PatientForm/DescribePainLevel/DescribePainLevelValidation';
import ImageCapture from '@brands/Dashboard/PatientForms/PatientForm/ImageCapture/ImageCaptureValidation';
import ImageUploadProcessValidation from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadProcessValidation';
import ImageUploadStepFive from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadStepFive';
import ImageUploadStepFour from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadStepFour';
import ImageUploadStepOne from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadStepOne';
import ImageUploadStepThree from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadStepThree';
import ImageUploadStepTwo from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadStepTwo';
import ImageUpload from '@brands/Dashboard/PatientForms/PatientForm/ImageUpload/ImageUploadValidation';
import PaymentInformation from '@brands/Dashboard/PatientForms/PatientForm/PaymentInformation/PaymentInformationValidation';
import SelectAppointmentType from '@brands/Dashboard/PatientForms/PatientForm/SelectAppointmentType/SelectAppointmentTypeValidation';
import SelectDataAndTime from '@brands/Dashboard/PatientForms/PatientForm/SelectDateAndTime/SelectDateAndTimeValidation';
import SelectDentaInsurance from '@brands/Dashboard/PatientForms/PatientForm/SelectDentaInsurance/SelectDentaInsuranceValidation';
import SelectNeeds from '@brands/Dashboard/PatientForms/PatientForm/SelectNeeds/SelectNeedsValidation';
import SelectPatient from '@brands/Dashboard/PatientForms/PatientForm/SelectPatient/SelectPatientValidation';
import VerifyInsurance from '@brands/Dashboard/PatientForms/PatientForm/VerifyInsurance/VerifyInsuranceValidation';
import PaymentPage from '@brands/Dashboard/PaymentPage/PaymentPageValidation';
import SmileScan from '@brands/Dashboard/SmileScan/SmileScan';
import Solutions from '@brands/Dashboard/Solutions/SolutionsValidation';
import useConnectionOptions from '@brands/hooks/video-hooks/useConnectionOptions';
import VideoRoom from '@brands/VideoRoom/VideoRoom/VideoRoom';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';

const Routing: FC = () => {
  const connectionOptions: ConnectOptions = useConnectionOptions();

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="smile-scan" element={<SmileScan />} />
        <Route path="scheduleAvailability" element={<ScheduleAvailability />} />
        <Route path="/verify-insurance" element={<VerifyInsurance />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="consultation-page" element={<ConsultationPage />} />
        <Route path="consultation-page/:caseId" element={<ConsultationPage />} />
        <Route path="smile-scan-report/:caseId" element={<SmileScanReportComponent />} />
        <Route path="my-profile" element={<MyProfile />} />
        <Route path="/admin/review-profile/:role/:userId" element={<ReviewProfile />} />
        <Route path="/admin/review-profile/:role/:userId/create-consultation-for" element={<CreateConsultationFor />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/my-profile" element={<AdminMyProfile />} />
        <Route path="admin/consultation-page/:caseId" element={<ConsultationPage />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="dental-history" element={<DentalHistory />} />
        <Route path="medical-condition" element={<MedicalCondition />} />
        <Route path="medical-history" element={<MedicalHistory />} />
        <Route path="medical-information-summary" element={<MedicalInformationSummary />} />
        <Route path="payment-page" element={<PaymentPage />} />
        <Route path="insurance-form" element={<InsuranceForm />} />
        <Route path="select-dental-insurance" element={<SelectDentaInsurance />} />
        <Route path="select-date-time" element={<SelectDataAndTime />} />
        <Route path="select-patient" element={<SelectPatient />} />
        <Route path="select-appointment-type" element={<SelectAppointmentType />} />
        <Route path="select-needs" element={<SelectNeeds />} />
        <Route path="describe-pain-level" element={<DescribePainLevel />} />
        <Route path="additional-information" element={<AdditionalInformation />} />
        <Route path="payment-information" element={<PaymentInformation />} />
        <Route path="create-your-profile" element={<CreateYourProfile />} />
        <Route path="upload-image" element={<ImageUpload />} />
        <Route path="upload-image-flow" element={<ImageUploadProcessValidation />} />
        <Route path="upload-image-flow-step-one" element={<ImageUploadStepOne />} />
        <Route path="upload-image-flow-step-two" element={<ImageUploadStepTwo />} />
        <Route path="upload-image-flow-step-three" element={<ImageUploadStepThree />} />
        <Route path="upload-image-flow-step-four" element={<ImageUploadStepFour />} />
        <Route path="upload-image-flow-step-five" element={<ImageUploadStepFive />} />
        <Route path="smile-scan-link" element={<ImageCapture />} />
        <Route path="case-chat" element={<CaseChatWindow />} />
        <Route path="case-chat/:caseId" element={<CaseChatWindow />} />
        <Route path="case-chat/:caseId/:conversationSid" element={<CaseChatWindow />} />
        <Route path="oral-health-report/:caseId" element={<OralHealthReportComponent />} />
        <Route path="consultation-page/:caseId" element={<ConsultationPage />} />
        <Route path="add-additional-information/:caseId" element={<AddAdditionalInformation />} />
        <Route
          path="/video-room/:caseId"
          element={
            <VideoProvider options={connectionOptions}>
              <VideoRoom />
            </VideoProvider>
          }
        />
      </Route>
      <Route path="sign-up" element={<SignUp />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/notice-of-privacy-practices" element={<NoticeOfPrivacyPractices />} />
      <Route path="washington_my_health_my_data_privacy_notice" element={<WashingtonMyHealthMyDataPrivacyNotice />} />
      <Route path="/consent-policy" element={<ConsentPolicy />} />
      <Route path="/smile-scan-disclaimer" element={<SmileScanDisclaimer />} />
      <Route path="complete-signup" element={<CompleteSignUp />} />
      <Route path="/" element={<SignIn />} />
      <Route path="/existing-account" element={<ExsistAccount />} />
      <Route path="/kare-mobile/sign-up" element={<SignUp />} />
      <Route path="/kare-mobile/login" element={<SignIn />} />
      <Route path="/admin/login" element={<SignIn />} />
      <Route path="/zendesk_logout" element={<ZendeskLogout />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routing;
