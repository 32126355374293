/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_mobile.svg';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { getOneCase } from '@brands/services/cases/getCaseById';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectDependents } from '@brands/store/selectors/dependents';
import { selectOrganization } from '@brands/store/selectors/organization';
import { getCaseDate, getCaseTime, getFormatDate } from '@brands/Utils/formatDate';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { FiEdit } from 'react-icons/fi';
import { TbMessage2 } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../../Components/Button/Button';
import { Loading } from '../../../../Components/LoadingSpinner/Loading';
import { useSubscribe } from '../../../../hooks';
import useCases from '../../../../hooks/useCases';
import { Subscriber } from '../../../../hooks/useSubscribe';
import { CaseType, ICase, Status } from '../../../../services/cases/types/ICase';
import {
  addAdditionalInformationAvailability,
  cancelCaseAvailability,
  getFriendlyNameOfConsultationType,
  rescheduleCaseAvailability,
  updatePaymentAvailability,
} from '../../../../Utils/getFriendlyName';
import { sortData, SortKeys, SortOrder } from '../../../../Utils/sortData';
import ProfileComponent from '../../../Dashboard/CareAdvisor/Component/ProfileComponent';
import styles from '../appointments.module.scss';
import CancelAppointmentModal from '../CancelAppointmentModal/CancelAppointmentModal';
import EditCreditCardModal from '../EditCreditCardModal/EditCreditCardModal';
import AddAdditionalInformationButton from './AddAdditionalInformationButton';
import CancelButton from './CancelButton';
import EditCreditCardButton from './EditCreditCardButton';
import JoinButton from './JoinButton';
import RescheduleButton from './RescheduleButton';

type AppointmentsProps = {
  isProfileCard?: boolean;
  refreshAll: () => Promise<void>;
  selectedPatientID: number;
};

const UpcomingAppointments = ({ isProfileCard, refreshAll, selectedPatientID }: AppointmentsProps): JSX.Element => {
  const currentState = useAppSelector(selectCurrentPatientState);
  const { userInfo } = useAppSelector(selectAuth);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [cancelCaseModal, setCancelCaseModal] = useState<boolean>(false);
  const [editCreditCardModal, setEditCreditCardModal] = useState<boolean>(false);
  const [thisCase, setCase] = useState<ICase>();
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastMyCasesItemRef = useRef<HTMLTableRowElement>(null);
  const { dependents } = useAppSelector(selectDependents);
  const { currentOrganization } = useAppSelector(selectOrganization);
  const [editAppointmentDropDown, setOpenAppointmentDropDown] = useState(0);
  const ref = useDetectClickOutside({ onTriggered: () => setOpenAppointmentDropDown(0) });

  const location = useLocation();

  const [appointments, setAppointments] = useState<ICase[]>([]);

  const params = useMemo(
    () => ({
      sort_by: 'created_at' as const,
      sort_direction: 'desc' as const,
      patient_id: selectedPatientID as number,
      status: [
        'new',
        'accepted_care_advisor',
        'accepted_provider',
        'in_progress_provider',
        'in_progress_care_advisor',
        'completed_by_provider',
      ],
      include_patient_care_advisor_conversation: true,
      include_patient_provider_conversation: true,
      type: [
        CaseType.one_report,
        CaseType.second_opinion,
        CaseType.video_call_scheduled,
        CaseType.phone_call_scheduled,
        CaseType.care_coordination,
      ],
    }),
    [selectedPatientID],
  );

  const { cases, dataLoading, reloadAll, loadNextPage } = useCases(params, true);

  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (
          [
            'case_updated',
            'case_new_message_created',
            'case_cancelled',
            'case_new_conversation_created',
            'case_completed',
          ].includes(message.command)
        ) {
          reloadAll();
        }
      },
      commands: [
        'case_updated',
        'case_new_message_created',
        'case_cancelled',
        'case_new_conversation_created',
        'case_completed',
      ],
    }),

    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  const checkIsDependentActive = (appointment: ICase): boolean => {
    const isDependent = appointment.patient?.guardian_id;
    if (!isDependent) return true;
    const dependentDetails = dependents.find(
      (dependent) => Number(dependent?.value) === Number(appointment.patient?.id),
    );
    const isDeactivated = isDependent && dependentDetails?.status === 'Active';
    return isDeactivated || true;
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (!queryParams.edit) return;

    async function init(): Promise<void> {
      const appointment = await getOneCase(Number(queryParams.edit), {
        include_patient_care_advisor_conversation: true,
        include_patient_provider_conversation: true,
        include_provider_care_advisor_conversation: false,
      });
      if (appointment && updatePaymentAvailability(appointment)) {
        setCase(appointment);
        setEditCreditCardModal(true);
      }
    }

    init();
  }, [location]);

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(handleIntersection);

      const currentRef = lastMyCasesItemRef.current;
      if (currentRef) {
        observer.current.observe(currentRef);
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [cases]);

  useEffect(() => {
    setAppointments(cases);
  }, [cases]);

  const navigate = useNavigate();

  const [sortKey] = useState<SortKeys>('created_at');
  const [sortOrder] = useState<SortOrder>('desc');

  const sortedData = useCallback(
    () => sortData({ tableData: appointments, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [appointments, sortKey, sortOrder],
  );

  const setOpenEditAppointment = (appointmentId: number): void => {
    setOpenAppointmentDropDown(appointmentId);
  };

  return (
    <div className={`${styles.innerContainer} mb-1`}>
      <div className={styles.mainTitle} style={{ display: isProfileCard ? 'none' : '' }}>
        Upcoming
      </div>
      {!dataLoading ? (
        appointments.filter(
          (appointment) =>
            appointment.type !== 'messaging' &&
            !(appointment.status === 'completed' || appointment.status === 'canceled'),
        ).length > 0 ? (
          <>
            {sortedData().map((appointment, index, array) => {
              const isLastItem = index === array.length - 1;
              return (
                <div className={styles.appointmentDivContainer} ref={isLastItem ? lastMyCasesItemRef : null}>
                  <div className={styles.appointmentLeftSide}>
                    <div className={styles.appointmentDetails}>
                      <div className={styles.providerImage}>
                        {appointment.type !== CaseType.smile_scan ? (
                          appointment.type !== CaseType.care_coordination ? (
                            <ProfileComponent
                              caseRow={appointment}
                              displayRole={false}
                              role="Provider"
                              showName={false}
                            />
                          ) : (
                            <ProfileComponent caseRow={appointment} displayRole={false} role="CA" showName={false} />
                          )
                        ) : (
                          <img className={styles.smileScan} src={SmileScanMobile} alt="SmileScan" />
                        )}
                      </div>
                      <div className={styles.appointmentInfo}>
                        <span className={styles.appointmentState}>
                          {(appointment.provider_id || appointment.type === CaseType.care_coordination) &&
                            getFriendlyNameOfConsultationType(appointment.type, appointment.status === Status.Canceled)}
                        </span>
                        {appointment.provider && (
                          <span className={styles.providerName}>
                            <ProfileComponent
                              caseRow={appointment}
                              displayRole={false}
                              role="Provider"
                              showImage={false}
                              maxChars={25}
                            />
                          </span>
                        )}
                        {appointment.type === CaseType.care_coordination && (
                          <span className={styles.providerName}>
                            <ProfileComponent
                              caseRow={appointment}
                              displayRole={false}
                              role="CA"
                              showImage={false}
                              maxChars={25}
                            />
                          </span>
                        )}
                        {(appointment.type === CaseType.video_call_scheduled ||
                          appointment.type === CaseType.video_call_instant ||
                          appointment.type === CaseType.phone_call_scheduled) && (
                          <span className={styles.appointmentVideoDate}>
                            <span>
                              {getCaseDate(appointment.video_conference_date || '', timeZone)}
                            </span>
                            <span>
                              {getCaseTime(appointment.video_conference_date || '', timeZone)}
                            </span>
                          </span>
                        )}
                        <span className={styles.appointmentDetailsInfo}>
                          <span>Requested</span>
                          <span>{getFormatDate(appointment.created_at || '', timeZone)}</span>
                        </span>
                        {appointment.type !== CaseType.care_coordination && (
                          <span className={`${styles.caNAme} ${styles.appointmentDetailsInfo}`}>
                            <span>Care Advisor</span>
                            <span>
                              <ProfileComponent
                                caseRow={appointment}
                                displayRole={false}
                                role="CA"
                                showImage={false}
                                maxChars={25}
                              />
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.appointmentRightSide}>
                    <div className={styles.appointmentControls}>
                      <div className={styles.controls}>
                        {appointment &&
                          (appointment.type === 'video_call_scheduled' ||
                            appointment.type === 'video_call_instant') && <JoinButton appointment={appointment} />}
                        {(appointment.patient_care_advisor_conversation_sid ||
                          appointment.patient_provider_conversation_sid) && (
                          <Button
                            className={styles.appBtn}
                            type="button"
                            onClick={() => navigate(`/case-chat/${appointment?.id}`)}
                          >
                            <TbMessage2 /> Messages
                          </Button>
                        )}
                      </div>
                      {appointment.type !== CaseType.care_coordination &&
                        ((checkIsDependentActive(appointment) &&
                          currentOrganization.payment_options.find(
                            (paymentOption: string) => paymentOption === 'Credit Card',
                          ) &&
                          updatePaymentAvailability(appointment)) ||
                          cancelCaseAvailability(appointment) ||
                          rescheduleCaseAvailability(appointment) ||
                          addAdditionalInformationAvailability(appointment)) && (
                          <div
                            ref={ref}
                            className={styles.controlsEdit}
                            onClick={() => setOpenEditAppointment(appointment.id)}
                          >
                            <button
                              className={styles.editBtn}
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenEditAppointment(appointment.id);
                              }}
                            >
                              <FiEdit className={styles.editIcon} />
                              Edit Appointment
                            </button>
                            {editAppointmentDropDown === appointment.id && (
                              <div ref={ref} className={`${styles.appointmentDropDown}`}>
                                {addAdditionalInformationAvailability(appointment) && (
                                  <span>
                                    <AddAdditionalInformationButton appointment={appointment} />
                                  </span>
                                )}
                                {checkIsDependentActive(appointment) &&
                                  currentOrganization.payment_options.find(
                                    (paymentOption: string) => paymentOption === 'Credit Card',
                                  ) &&
                                  updatePaymentAvailability(appointment) && (
                                    <span>
                                      <EditCreditCardButton
                                        appointment={appointment}
                                        setEditCreditCardModal={setEditCreditCardModal}
                                        setCase={setCase}
                                        updatePaymentAvailabilityCheck={updatePaymentAvailability(appointment)}
                                      />
                                    </span>
                                  )}
                                {rescheduleCaseAvailability(appointment) && (
                                  <span>
                                    <RescheduleButton
                                      appointment={appointment}
                                      rescheduleAvailability={rescheduleCaseAvailability(appointment)}
                                    />
                                  </span>
                                )}
                                {cancelCaseAvailability(appointment) && (
                                  <span>
                                    <CancelButton
                                      appointment={appointment}
                                      setCancelCaseModal={setCancelCaseModal}
                                      setCase={setCase}
                                      cancelAvailability={cancelCaseAvailability(appointment)}
                                    />
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
            {isLoading && <Loading dotted />}
          </>
        ) : (
          <span className={styles.noData}>No data to display</span>
        )
      ) : (
        <span>
          <Loading dotted />
        </span>
      )}
      {cancelCaseModal && (
        <CancelAppointmentModal
          setOpenModal={setCancelCaseModal}
          thisCase={thisCase!}
          reloadAll={reloadAll}
          refreshAll={refreshAll}
        />
      )}
      {editCreditCardModal && (
        <EditCreditCardModal
          setOpenModal={setEditCreditCardModal}
          thisCase={thisCase!}
          reloadAll={reloadAll}
          refreshAll={refreshAll}
        />
      )}
    </div>
  );
};
UpcomingAppointments.defaultProps = {
  isProfileCard: false,
};
export default UpcomingAppointments;
