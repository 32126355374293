import { CaseType } from '@brands/services/cases/types/ICase';
import moment from 'moment-timezone';

import { formatDate } from './formatDate';

export const formatDateWithAddedDay = (dateString: string, isTzSeen = false): string => {
  const date = moment.utc(dateString).add(24, 'hours'); // Add 24 hours to the UTC date
  const timezone = moment.tz.guess();

  const dateWithTimezone = date.tz(timezone);
  const formattedDate = dateWithTimezone.format('MM/DD/YYYY hh:mm A (z)');
  const formattedDateWithoutTimeZone = dateWithTimezone.format('MM/DD/YYYY hh:mm A');
  return isTzSeen ? formattedDate : formattedDateWithoutTimeZone;
};

export const calculateCaseDue = (caseType: string, date: string, vDate: string, isTzSeen = false): string => {
  switch (caseType) {
    case 'messaging':
      return 'Now';
    case 'video_call_instant':
      return 'Now';
    case 'one_report':
      return formatDateWithAddedDay(date, isTzSeen);
    case 'second_opinion':
    case CaseType.care_coordination:
      return formatDateWithAddedDay(date, isTzSeen);
    case 'video_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDate(vDate, isTzSeen);
      }
    case 'phone_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDate(vDate, isTzSeen);
      }
    default:
      return formatDate(vDate);
  }
};

export const calculatPatientAge = (dob: string): string => {
  const dobDate = new Date(dob);

  const currentDate = new Date();

  const diffInMs = currentDate.getTime() - dobDate.getTime();

  const ageInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));

  return ageInYears.toString();
};

export const calculateProviderCaseDue = (caseType: string, date: string, vDate: string): string => {
  switch (caseType) {
    case 'messaging':
      return 'Now';
    case 'video_call_instant':
      return 'Now';
    case 'one_report':
      return formatDateWithAddedDay(date);
    case 'second_opinion':
      return formatDateWithAddedDay(date);
    case 'video_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDateWithAddedDay(vDate);
      }
    case 'phone_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDateWithAddedDay(vDate);
      }
    default:
      return formatDate(vDate);
  }
};

export const calculateCaseScheduled = (caseType: string, vDate: string): string => {
  switch (caseType) {
    case 'messaging':
      return 'N/A';
    case 'video_call_instant':
      return 'N/A';
    case 'one_report':
      return 'N/A';
    case 'second_opinion':
      return 'N/A';
    case 'video_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDate(vDate);
      }
    case 'phone_call_scheduled':
      switch (vDate) {
        case '':
          return 'TBD';
        default:
          return formatDate(vDate);
      }
    default:
      return 'N/A';
  }
};

export const calculateCaseDueInMinutes = (caseType: string, date: string, vDate: string): number => {
  const currentDate = new Date();
  let caseDate = vDate;
  if ((caseType === 'video_call_scheduled' || caseType === CaseType.phone_call_scheduled) && caseDate === '') {
    return Infinity;
  }

  // If it's a video_call_instant case type, set the caseDate to the current date
  if (caseType === 'video_call_instant') {
    caseDate = currentDate.toISOString();
  } else {
    caseDate = caseType === 'one_report' || caseType === 'second_opinion' ? formatDateWithAddedDay(date) : vDate;
  }

  const dueDate = new Date(caseDate);
  const timeDiff = dueDate.getTime() - currentDate.getTime();
  const minutesDiff = Math.floor(timeDiff / (1000 * 60));

  switch (caseType) {
    case 'messaging':
      return -Infinity;
    case 'one_report':
    case 'second_opinion':
    case 'video_call_scheduled':
    case 'phone_call_scheduled':
    case 'video_call_instant':
      return minutesDiff; // ensure return value is non-negative
    default:
      return minutesDiff; // ensure return value is non-negative
  }
};

export const calculateCaseDueInMinutesForPatient = (caseType: string, date: string, vDate: string): number => {
  const currentDate = new Date();
  let caseDate = vDate;
  // If it's a video_call_instant case type, set the caseDate to the current date
  if (caseType === 'video_call_instant') {
    caseDate = currentDate.toISOString();
  } else {
    caseDate = caseType === 'one_report' || caseType === 'second_opinion' ? formatDateWithAddedDay(date) : vDate;
  }
  if (caseDate === '') {
    return -Infinity;
  }

  const dueDate = new Date(Date.parse(`${caseDate}Z`)); // use UTC time
  const timeDiff = Math.ceil((dueDate.getTime() - currentDate.getTime()) / 1000); // use seconds and round up
  const minutesDiff = Math.ceil(timeDiff / 60); // convert to minutes and round up

  switch (caseType) {
    case 'video_call_instant':
      return Infinity; // If it's a video_call_instant case type, set the waiting time to the maximum possible value
    case 'one_report':
    case 'second_opinion':
    case 'video_call_scheduled':
    case CaseType.phone_call_scheduled:
      return Math.max(minutesDiff, 0); // ensure return value is non-negative
    default:
      return Math.max(minutesDiff, 0); // ensure return value is non-negative
  }
};
