/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import careCoordination from '@brands/Dashboard/Dashboard/CareAdvisor/assets/careCoordination.svg';
import { useAppSelector } from '@brands/hooks/useReduxHook';
import { CaseType } from '@brands/services/cases/types/ICase';
import { selectAuth } from '@brands/store/selectors/auth';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { TiMessages } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

import Button from '../../../Components/Button/Button';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import useCases from '../../../hooks/useCases';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import { calculateCaseDue } from '../../../Utils/calculateCaseDue';
import { formatDate } from '../../../Utils/formatDate';
import { getColorForStatus, getTextColorForStatus } from '../../../Utils/getColorForStatus';
import { getFriendlyName } from '../../../Utils/getFriendlyName';
import { getTextForStatus } from '../../../Utils/getTextForStatus';
import { sortData, SortKeys, SortOrder } from '../../../Utils/sortData';
import video from '../../Dashboard/CareAdvisor/assets/liveVideo.svg';
import report from '../../Dashboard/CareAdvisor/assets/oralHealthReport.svg';
import phone from '../../Dashboard/CareAdvisor/assets/phone.svg';
import sVideo from '../../Dashboard/CareAdvisor/assets/scheduledVideo.svg';
import secondOpinion from '../../Dashboard/CareAdvisor/assets/secondOpinion.svg';
import ProfileComponent from '../../Dashboard/CareAdvisor/Component/ProfileComponent';
import LinkWithUnderline from '../Components/LinkWithUnderline';
import styles from './styles.module.scss';

interface ReviewProviderCasesProps {
  providerId: number;
  role: string;
}

const ReviewProviderCases = ({ providerId, role }: ReviewProviderCasesProps): JSX.Element => {
  const [activeLink, setActiveLink] = useState<number | null>(1);
  const [casesparams, setCasesParams] = useState({
    sort_by: 'created_at' as const,
    sort_direction: 'desc' as const,
    ...(role === UserRoleName.Provider ? { provider_id: Number(providerId) } : { care_advisor_id: Number(providerId) }),
    status:
      activeLink === 2
        ? role === UserRoleName.Provider
          ? ['completed', 'canceled', 'in_progress_care_advisor', 'completed_by_provider']
          : ['canceled', 'completed']
        : role === UserRoleName.Provider
        ? ['accepted_provider', 'in_progress_provider']
        : ['accepted_care_advisor', 'accepted_provider', 'in_progress_care_advisor', 'in_progress_provider'],
    limit: 15,
  });

  const { cases, loadNextPage, dataLoading } = useCases(casesparams, true);

  useEffect(() => {
    setCasesParams((prevParams) => ({
      ...prevParams,
      ...(role === UserRoleName.Provider
        ? { provider_id: Number(providerId) }
        : { care_advisor_id: Number(providerId) }),
      status:
        activeLink === 2
          ? role === UserRoleName.Provider
            ? ['completed', 'canceled', 'in_progress_care_advisor', 'completed_by_provider']
            : ['canceled', 'completed']
          : role === UserRoleName.Provider
          ? ['accepted_provider', 'in_progress_provider']
          : ['accepted_care_advisor', 'accepted_provider', 'in_progress_care_advisor', 'in_progress_provider'],
    }));
  }, [providerId, activeLink, role]);

  const [hoverText, setHoverText] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useRef<HTMLTableRowElement>(null);
  const loadMore = useCallback((): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  }, [loadNextPage]);

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleIntersection);
    if (lastItemRef.current) observer.current.observe(lastItemRef.current);
  }, [cases]);

  const headers: { key: SortKeys; label: string; isSorted: boolean }[] = [
    { key: 'id', label: 'ID', isSorted: false },
    { key: 'id', label: 'Case ID', isSorted: false },
    { key: 'created_at', label: 'Requested Date', isSorted: false },
    { key: 'completed_at', label: 'Completed', isSorted: false },
    { key: 'due_date', label: 'Scheduled', isSorted: false },
    { key: 'type', label: 'Type', isSorted: false },
    { key: 'patient', label: 'Patient', isSorted: false },
    { key: 'status', label: 'Status', isSorted: false },
  ];

  const [headersState, setHeadersState] = useState(headers.map((header) => ({ ...header, isSorted: false })));
  const [sortKey, setSortKey] = useState<SortKeys>('due_date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('ascn');

  const { userInfo } = useAppSelector(selectAuth);

  const sortedData = useCallback(
    () => sortData({ tableData: cases, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [cases, sortKey, sortOrder],
  );

  const changeSort = (key: SortKeys): void => {
    setSortOrder((prevOrder) => (prevOrder === 'ascn' ? 'desc' : 'ascn'));
    setSortKey(key);
    setHeadersState((prevHeaders) =>
      prevHeaders.map((header) => ({
        ...header,
        isSorted: header.key === key,
      })),
    );
  };

  const navigate = useNavigate();

  return (
    <div className={styles.containerPf}>
      <div className={styles.filterDiv}>
        <LinkWithUnderline text="Upcoming" id={1} activeLink={activeLink} setActiveLink={setActiveLink} />
        <LinkWithUnderline text="Completed" id={2} activeLink={activeLink} setActiveLink={setActiveLink} />
      </div>
      <div className={styles.providerDashboardBody}>
        {!dataLoading ? (
          cases && cases.filter((caseRow) => caseRow.type !== 'messaging').length > 0 ? (
            <>
              <table className={styles.providerDashboardTable}>
                <thead className={`${styles.providerListTableHeader} ${styles.dashboardHeaderName} ${styles.titles}`}>
                  <tr>
                    {headersState.map((row) => {
                      if (
                        (activeLink === 2 && row.key !== 'due_date') ||
                        (activeLink === 1 && row.key !== 'completed_at')
                      ) {
                        return (
                          <th key={row.key} onClick={() => changeSort(row.key)}>
                            <span className={`${sortKey === row.key ? styles.bold : ''}`}>
                              {row.label}
                              {row.isSorted && sortOrder === 'ascn' ? <HiChevronDown /> : <HiChevronUp />}
                            </span>
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sortedData()
                    .filter((caseRow) => caseRow.type !== 'messaging')
                    .map((caseRow) => {
                      return (
                        <React.Fragment key={caseRow.id}>
                          <tr className={`${styles.providerListTableBody}`}>
                            <td className={`${styles.consultType} ${styles.onDesktop}`}>
                              <div className={styles.cellContainer}>
                                <div className={styles.mobileHeader}>Case ID</div>
                                {caseRow.id}
                              </div>
                            </td>
                            <td className={styles.mobileDue}>
                              <div className={`${styles.due}`}>
                                <span className={styles.mobileHeader}>Requested Date</span>
                                <span className={styles.mobileDueDate}>{formatDate(caseRow.created_at)}</span>
                              </div>
                            </td>
                            {activeLink === 2 && (
                              <td className={`${styles.consultType} ${styles.onDesktop}`}>
                                <div className={styles.cellContainer}>
                                  <div className={styles.mobileHeader}>Completed</div>
                                  {caseRow.status !== 'canceled' && caseRow.completed_at !== null
                                    ? formatDate(caseRow.completed_at)
                                    : caseRow.completed_at !== null
                                    ? formatDate(caseRow.updated_at || '')
                                    : caseRow.status === 'canceled'
                                    ? formatDate(caseRow.updated_at || '')
                                    : 'TBD'}
                                  {}
                                </div>
                              </td>
                            )}
                            {activeLink === 1 && (
                              <td className={`${styles.mobileDue} ${styles.duaOnMobile}`}>
                                <div className={`${styles.due}`}>
                                  <span className={styles.mobileHeader}>Due</span>
                                  <span className={styles.mobileDueDate}>
                                    {calculateCaseDue(
                                      caseRow.type,
                                      caseRow.started_at,
                                      caseRow.video_conference_date || '',
                                    )}
                                  </span>
                                </div>
                              </td>
                            )}
                            <td className={styles.mobileCType}>
                              <div className={`col-md-2 ${styles.availability}`}>
                                <span className={styles.mobileHeader}>Type</span>
                                {caseRow.type === 'messaging' && (
                                  <TiMessages
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_instant' && (
                                  <img
                                    className={styles.video}
                                    src={video}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === CaseType.care_coordination && (
                                  <img
                                    className={styles.careCoordination}
                                    src={careCoordination}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'video_call_scheduled' && (
                                  <img
                                    className={styles.sVideo}
                                    src={sVideo}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === CaseType.phone_call_scheduled && (
                                  <img
                                    className={styles.sVideo}
                                    src={phone}
                                    alt="Phone"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'one_report' && (
                                  <img
                                    className={styles.cReport}
                                    src={report}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {caseRow.type === 'second_opinion' && (
                                  <img
                                    className={styles.cReport}
                                    src={secondOpinion}
                                    alt="video"
                                    onMouseEnter={() => setHoverText(caseRow.id)}
                                    onMouseLeave={() => setHoverText(0)}
                                  />
                                )}
                                {hoverText === caseRow.id && (
                                  <div className={styles.hoverText}>{getFriendlyName(caseRow.type)}</div>
                                )}
                              </div>
                            </td>
                            <td className={styles.mobilePatient} aria-label="Patient">
                              <ProfileComponent caseRow={caseRow} role="Patient" />
                            </td>
                            <td className={styles.mobileStatus}>
                              <div>
                                <span className={styles.mobileHeader}>Status</span>
                                {(() => {
                                  for (const key in caseRow) {
                                    if (key === 'status') {
                                      return (
                                        <span
                                          className={styles.statusSpan}
                                          style={{
                                            backgroundColor: getColorForStatus(caseRow[key]),
                                            color: getTextColorForStatus(caseRow[key]),
                                          }}
                                        >
                                          {getTextForStatus(role, caseRow.type, caseRow[key])}
                                        </span>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.viewBtn}`}>
                                <div className={styles.internalDiv}>
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      navigate(`/admin/consultation-page/${caseRow.id}`);
                                    }}
                                  >
                                    View
                                  </Button>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className={styles.startTime}>
                            <td colSpan={4} aria-label="Empty cell" />
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  <tr className={styles.lastItemRef} ref={lastItemRef} />
                </tbody>
              </table>
              {isLoading && <Loading dotted />}
            </>
          ) : (
            <span className={styles.noData}>No data to display</span>
          )
        ) : (
          <span>
            <Loading dotted />
          </span>
        )}
      </div>
    </div>
  );
};

export default ReviewProviderCases;
