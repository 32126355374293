import React from 'react';

import styles from './styles.module.scss';

interface DashboardHeaderProps {
  titles: string[];
  activeTitle: number;
  setTab: (tabIndex: number) => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ titles, activeTitle, setTab }) => {
  return (
    <div className={styles.dashboardHeaderContainer}>
      {titles.map((title, index) => (
        <div
          key={title}
          onClick={() => {
            setTab(index);
          }}
          className={`${styles.title} ${activeTitle === index ? styles.selected : styles.notSelected}`}
          style={{
            cursor: 'pointer',
          }}
        >
          {title}
        </div>
      ))}
    </div>
  );
};

export default DashboardHeader;
