/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
import { getForm } from '@brands/services/forms/getForm';
import { getFormAnswer } from '@brands/services/forms/getFormAnswer';
import { listForms } from '@brands/services/forms/listForms';
import { IFormDetails } from '@brands/services/forms/types/IFormDetails';
import { addOneReportObject } from '@brands/services/oneReport/addOneReportObject';
import { deleteOneReportObject } from '@brands/services/oneReport/deleteOneReportObject';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { AiFillPlusCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';

import CustomDropdown from '../../../../Components/Inputs/CustomDropdown/CustomDropdown';
import TextArea from '../../../../Components/Inputs/TextArea/TextArea';
import { useWindowSize } from '../../../../hooks';
import { getCasePatientRx } from '../../../../services/cases/getCasePatientRx';
import { ICase, Status } from '../../../../services/cases/types/ICase';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { IOneReport } from '../../../../services/oneReport/types/IOneReport';
import { ConvertedTreatment, ITreatments, TreatmentPayload } from '../../../../services/oneReport/types/ITreatments';
import { consultationSelectStyles } from '../../../../Utils/customStyles';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import {
  oneReportTreatmentsCategory,
  oneReportTreatmentsToothNumber,
  oneReportTreatmentsUrgency,
} from '../../../../Utils/selectOptions';
import { OralHealthReportSubmit, Treatment } from '../../assets/types';
import styles from '../../styles.module.scss';
import FollowUpSection from '../FollowUpSection/FollowUpSection';
import PharmacySection from '../PharmacySection/PharmacySection';
import PrescriptionSection, { convertPrescriptionType } from '../PrescriptionSection/PrescriptionSection';

type PossibleTreatmentNeedsSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<React.SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
  routeCaseId: string;
  addRequiredField: boolean;
  setAddRequiredField: React.Dispatch<React.SetStateAction<boolean>>;
};

interface FormValues {
  medicalHistory: Record<string, { type: string; value: string }>;
}

export const convertTreatmentType = (iTreatments: ITreatments[]): Treatment[] => {
  return iTreatments.map((treatment) => ({
    category: {
      label:
        treatment.category !== null ? treatment.category?.charAt(0).toUpperCase() + treatment.category?.slice(1) : '',
      value: treatment.category !== null ? treatment.category : '',
    },
    tooth: {
      label:
        treatment.tooth_number !== null
          ? treatment.tooth_number?.toString().charAt(0).toUpperCase() + treatment.tooth_number?.toString().slice(1)
          : '',
      value: treatment.tooth_number !== null ? treatment.tooth_number?.toString() : '',
    },
    toothArea: {
      label:
        treatment.tooth_area !== null
          ? treatment.tooth_area?.toString().charAt(0).toUpperCase() + treatment.tooth_area?.toString().slice(1)
          : '',
      value: treatment.tooth_area !== null ? treatment.tooth_area?.toString() : '',
    },
    urgency: {
      label: treatment.urgency !== null ? treatment.urgency?.charAt(0).toUpperCase() + treatment.urgency?.slice(1) : '',
      value: treatment.urgency !== null ? treatment.urgency : '',
    },
    description: treatment.description
      ? treatment.description?.charAt(0).toUpperCase() + treatment.description?.slice(1)
      : '',
    id: treatment.id,
  }));
};

const PossibleTreatmentNeedsSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
  routeCaseId,
  addRequiredField,
  setAddRequiredField,
}: PossibleTreatmentNeedsSectionProps): JSX.Element => {
  const screenSize = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [treatments, setTreatments] = useState<Treatment[]>([]);

  const [loading, setLoading] = useState(true);

  const [formValues, setFormValues] = useState<FormValues>({
    medicalHistory: {},
  });

  const getFormAnswers = useCallback(async (createdByPatientId: number | undefined) => {
    setLoading(true);
    const { forms: formList } = await listForms();
    const newFormId = formList.find((form) => form.title === 'Patient Medical Information Form')?.id ?? 0;
    let questionIdsForMedicalHistory: number[] = [];
    let questions: IFormDetails;

    const formDetails = await getForm(newFormId.toString());
    if (formDetails) {
      questions = formDetails;
      questionIdsForMedicalHistory = questions.steps[0].questions.map((question) => question.id);
    }
    const formAnswers = await getFormAnswer({ form_id: newFormId, created_by_id: createdByPatientId });

    const medicalHistoryValues: {
      title: string;
      type: string;
      value: string;
    }[] = [];

    if (formAnswers.form_answers.length > 0) {
      let medicalHistoryFilled = false;

      formAnswers.form_answers
        .sort((a, b) => a.id - b.id)
        .forEach((answer) => {
          if (medicalHistoryFilled) return;

          const tempValues: any = [];
          answer.values
            .sort((a, b) => a.form_question_id - b.form_question_id)
            .forEach((value) => {
              if (questionIdsForMedicalHistory.includes(value.form_question_id) && value.value.data !== 'no') {
                tempValues.push({
                  title:
                    questions.steps[0].questions.find((question) => question.id === value.form_question_id)?.title ||
                    '',
                  type:
                    questions.steps[0].questions.find((question) => question.id === value.form_question_id)?.type || '',
                  value: value.value.data,
                });
              }
            });

          if (tempValues.length > 0) {
            medicalHistoryValues.push(...tempValues);
            medicalHistoryFilled = true;
          }
        });
    }

    setFormValues({
      medicalHistory: medicalHistoryValues.reduce((acc, cur) => {
        acc[cur.title] = { type: cur.type, value: cur.value };
        return acc;
      }, {} as Record<string, { type: string; value: string }>),
    });

    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getFormAnswers(Number(thisCase.patient_id));
      setLoading(false);
    })();
  }, []);

  const checkAtLeastOneFieldIsFilled = (treatment: Treatment): boolean => {
    if (treatment) {
      const { category, tooth, toothArea, description, urgency } = treatment;
      if (tooth.value || toothArea.value || category.value || description.trim() !== '' || urgency.value) {
        return true;
      }
    }
    return false;
  };

  const getConvertedTreatments = (res: IOneReport): ConvertedTreatment[] => {
    return convertTreatmentType(res?.treatments ?? []).map((currentTreatment: Treatment) => ({
      ...currentTreatment,
      category: {
        ...currentTreatment.category,
        value: currentTreatment.category.value || '',
      },
      tooth: {
        ...currentTreatment.tooth,
        value: currentTreatment.tooth.value || '',
      },
      toothArea: {
        ...currentTreatment.toothArea,
        value: currentTreatment.toothArea.value || '',
      },
      urgency: {
        ...currentTreatment.urgency,
        value: currentTreatment.urgency.value || '',
      },
      description: currentTreatment.description || '',
    }));
  };

  const addEmptyTreatmentRow = async (): Promise<void> => {
    try {
      const { treatments: res } = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Treatment, {
        description: '',
      });
      const sortedRes = res.sort((a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity));
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertTreatmentType(sortedRes)],
      });
    } catch (error) {
      console.error('Error adding new resource:', error);
    }
  };

  const addInitialOneReportObjects = async (): Promise<void> => {
    try {
      const caseId = parseInt(routeCaseId, 10);
      window.open('', 'formpopup', 'width=1500,height=1000,resizeable,scrollbars');
      const icorePayload = await getCasePatientRx(caseId);

      const loginform = document.getElementById('icoreForm');
      const loginInput = document.getElementById('icoreFormData');

      if (loginform && loginInput) {
        (loginform as HTMLFormElement).target = 'formpopup';
        (loginInput as HTMLInputElement).value = JSON.stringify(icorePayload);
        (loginform as HTMLFormElement).submit();
      }

      if (
        thisCase.one_report.prescriptions.length === 0 &&
        userInfo.role.name === UserRoleName.Provider &&
        thisCase.status === Status.InProgressProvider
      ) {
        setAddRequiredField(true);

        // Dynamically build the array of API calls
        const apiCalls = [];

        // Conditionally add Prescription object
        if (!thisCase?.one_report?.prescriptions?.length) {
          apiCalls.push(
            addOneReportObject(thisCase?.one_report.id || '', ObjectType.Prescription, {
              drug: '',
              sig: '',
              prescription_date: '',
              dispense: '',
              refills: '',
            }),
          );
        }

        // Conditionally add Pharmacy object
        if (!thisCase?.one_report?.pharmacies) {
          apiCalls.push(
            addOneReportObject(thisCase?.one_report.id || '', ObjectType.Pharmacy, {
              pharmacy_name: '',
              pharmacy_street_address: '',
              pharmacy_city: '',
              pharmacy_state: '',
              pharmacy_zip_code: '',
              pharmacy_phone_number: '',
            }),
          );
        }

        // Conditionally add Follow-up object
        if (!thisCase?.one_report?.follow_ups) {
          apiCalls.push(
            addOneReportObject(thisCase?.one_report.id || '', ObjectType.FollowUp, {
              care_coordination: false,
              oral_health_coaching: false,
              care_coordination_type: '',
              care_coordination_urgency: '',
              care_coordination_reason: '',
              oral_health_coaching_type: '',
              oral_health_coaching_urgency: '',
              oral_health_coaching_reason: '',
            }),
          );
        }

        // Execute all required API calls in parallel
        const results = await Promise.all(apiCalls);

        // Extract results based on their order in the `apiCalls` array
        const [prescriptionsRes, pharmacyRes, followUpRes] = results;

        // Sort the prescriptions array
        const sortedPrescriptions = prescriptionsRes
          ? prescriptionsRes.prescriptions.sort(
              (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
            )
          : [];

        // Update consultationForm with the new data
        setConsultationForm({
          ...consultationForm,
          prescriptions: !thisCase?.one_report?.prescriptions?.length
            ? prescriptionsRes
              ? [...convertPrescriptionType(sortedPrescriptions)]
              : thisCase.one_report?.prescriptions
            : [],
          pharmacies: !thisCase?.one_report?.pharmacies
            ? pharmacyRes?.pharmacies
            : thisCase?.one_report?.pharmacies || [],
          followUp: !thisCase?.one_report?.follow_ups
            ? followUpRes?.follow_ups
            : thisCase?.one_report?.follow_ups || [],
        });

        // Update the `thisCase` state with the final one_report object
        setCase({
          ...thisCase,
          one_report: {
            ...thisCase.one_report,
            prescriptions: !thisCase?.one_report?.prescriptions?.length
              ? sortedPrescriptions
              : thisCase.one_report?.prescriptions,
            pharmacies: !thisCase?.one_report?.pharmacies
              ? pharmacyRes?.pharmacies
              : thisCase?.one_report?.pharmacies || [],
            follow_ups: !thisCase?.one_report?.follow_ups
              ? followUpRes?.follow_ups
              : thisCase?.one_report?.follow_ups || [],
          },
        });
      }
    } catch (error) {
      console.error('Error adding new report objects:', error);
    }
  };

  const addTreatment = async (nonEmptyPayload: TreatmentPayload): Promise<void> => {
    try {
      const res = await addOneReportObject(thisCase?.one_report.id || '', ObjectType.Treatment, nonEmptyPayload);
      const convertedTreatments: ConvertedTreatment[] = getConvertedTreatments(res);
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertedTreatments],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const updateTreatment = async (id: string, nonEmptyPayload: TreatmentPayload): Promise<void> => {
    try {
      const res = await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.Treatment, {
        ...nonEmptyPayload,
        id,
      });
      const convertedTreatments: ConvertedTreatment[] = getConvertedTreatments(res);
      setConsultationForm({
        ...consultationForm,
        treatments: [...convertedTreatments],
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const deleteTreatment = async (index: number, id?: string): Promise<void> => {
    try {
      if (id) {
        const res = await deleteOneReportObject(thisCase?.one_report.id || '', id, ObjectType.Treatment);
        const sortedRes = res.treatments.sort(
          (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
        );
        if (res.treatments.length === 0) {
          setValue(`treatments`, []);
          trigger('treatments');
        }
        setConsultationForm({
          ...consultationForm,
          treatments: [...convertTreatmentType(sortedRes)],
        });
      } else {
        setConsultationForm({
          ...consultationForm,
          treatments: consultationForm.treatments.filter((treatment, i) => i !== index),
        });
      }
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handleTreatmentOnBlur = async (treatment: Treatment): Promise<void> => {
    const { id } = treatment;
    const nonEmptyPayload = {
      ...(treatment.category.value && { category: treatment.category.value }),
      ...(treatment.tooth.value && { tooth_number: treatment.tooth.value }),
      ...(treatment.toothArea.value && { tooth_area: treatment.toothArea.value }),
      ...(treatment.description ? { description: treatment.description } : { description: '' }),
      ...(treatment.urgency.value && { urgency: treatment.urgency.value }),
    };

    if (id !== undefined && checkAtLeastOneFieldIsFilled(treatment)) {
      await updateTreatment(id, nonEmptyPayload);
    } else if (id === undefined && checkAtLeastOneFieldIsFilled(treatment)) {
      await addTreatment(nonEmptyPayload);
    }
  };

  useEffect(() => {
    const sortedTreatments = consultationForm.treatments.sort(
      (a, b) => Number(a.id?.valueOf() ?? Infinity) - Number(b.id?.valueOf() ?? Infinity),
    );
    const filteredTreatments = sortedTreatments.filter((treatment) => {
      if (
        treatment.tooth.value ||
        treatment.toothArea.value ||
        treatment.category.value ||
        treatment.description.trim() !== ''
      ) {
        return true;
      }
      return false;
    });
    if (checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit') {
      setTreatments(filteredTreatments);
    } else {
      setTreatments(consultationForm.treatments);
    }
  }, [consultationForm.treatments]);
  return (
    <>
      {treatments.map((treatment, index) => (
        <div className={styles.descriptionInputs} style={{ marginTop: index !== 0 ? '2rem' : '' }} key={index}>
          <div className={styles.descriptionInputsFirstGroup}>
            <div className={styles.selectInput}>
              <div className={styles.title}>Category*</div>
              <div className={styles.input}>
                <CustomDropdown
                  options={oneReportTreatmentsCategory}
                  value={treatment?.category !== null ? treatment.category : { label: '', value: '' }}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  onChange={(value: any) => {
                    setConsultationForm({
                      ...consultationForm,
                      treatments: [
                        ...consultationForm.treatments.slice(0, index),
                        {
                          ...consultationForm.treatments[index],
                          category: value,
                        },
                        ...consultationForm.treatments.slice(index + 1),
                      ],
                    });
                    handleTreatmentOnBlur({
                      ...treatment,
                      category: value,
                    });
                    setValue(`treatments.${index}.category`, value!);
                    if (errors.treatments) trigger('treatments');
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            <div className={styles.selectInput}>
              <div className={styles.title}>Tooth # / Area*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`treatments.${index}.tooth`)}
                  styles={consultationSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={oneReportTreatmentsToothNumber}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={treatment?.tooth ?? { label: '', value: '' }}
                  onChange={(value: any) => {
                    setConsultationForm({
                      ...consultationForm,
                      treatments: [
                        ...consultationForm.treatments.slice(0, index),
                        {
                          ...consultationForm.treatments[index],
                          tooth: value,
                        },
                        ...consultationForm.treatments.slice(index + 1),
                      ],
                    });
                    handleTreatmentOnBlur({
                      ...treatment,
                      tooth: value,
                    });
                    setValue(`treatments.${index}.tooth`, value!);
                    if (errors.treatments) trigger('treatments');
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            <div className={styles.selectInput}>
              <div className={styles.title}>Urgency*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`treatments.${index}.urgency`)}
                  styles={consultationSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={oneReportTreatmentsUrgency}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={treatment?.urgency ?? { label: '', value: '' }}
                  onChange={(value: any) => {
                    setConsultationForm({
                      ...consultationForm,
                      treatments: [
                        ...consultationForm.treatments.slice(0, index),
                        {
                          ...consultationForm.treatments[index],
                          urgency: value,
                        },
                        ...consultationForm.treatments.slice(index + 1),
                      ],
                    });
                    handleTreatmentOnBlur({
                      ...treatment,
                      urgency: value,
                    });
                    setValue(`treatments.${index}.urgency`, value!);
                    if (errors.treatments) trigger('treatments');
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
          <div className={styles.descriptionInput}>
            <div className={styles.title}>Description*</div>
            <TextArea
              {...register(`treatments.${index}.description`)}
              wrapperStyle={{
                marginTop: '0',
                borderBottom: 'none',
                minHeight: '30px',
                height: 'auto',
              }}
              inputClass={styles.input}
              placeholder=""
              isUnderlined={false}
              defaultValue={treatment?.description ?? ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setConsultationForm({
                  ...consultationForm,
                  treatments: [
                    ...consultationForm.treatments.slice(0, index),
                    {
                      ...consultationForm.treatments[index],
                      description: e.target.value,
                    },
                    ...consultationForm.treatments.slice(index + 1),
                  ],
                });
                setValue(`treatments.${index}.description`, e.target.value);
                if (errors.treatments) trigger('treatments');
              }}
              onBlur={(e: any) =>
                handleTreatmentOnBlur({
                  ...treatment,
                  description: e.target.value,
                })
              }
              disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
            />
          </div>
          {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
            <div
              className={styles.deleteDescription}
              onClick={() => deleteTreatment(index, treatment.id)}
              style={{ marginTop: index !== 0 && screenSize.width >= 992 ? '0' : '' }}
            >
              <IoTrashOutline className="w-100 h-100" />
            </div>
          )}
        </div>
      ))}
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, '') === 'edit' && (
        <div className={styles.addTreatment}>
          <div className={styles.addTreatmentContent} onClick={addEmptyTreatmentRow}>
            <span>Add Recommended Treatment</span>
            <span className={styles.addTreatmentIcon}>
              <AiFillPlusCircle />
            </span>
          </div>
        </div>
      )}
      {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'E-Prescribe') === 'edit' && (
        <>
          <hr className={styles.ohrHr} />
          <div className={styles.subjective}>
            <span className={styles.subjectiveSpan}>E-Prescribe</span>
          </div>
          <div className={styles.addPrescribe}>
            <div className={styles.addPrescribeContent} onClick={addInitialOneReportObjects}>
              <span>Access E-prescribe Platform</span>
            </div>
          </div>
        </>
      )}
      {(addRequiredField ||
        thisCase.one_report?.follow_ups !== null ||
        thisCase.one_report?.prescriptions?.length > 0) && (
        <>
          <div className={styles.subjective}>
            <span className={styles.subjectiveSpan}>Prescription</span>
          </div>
          <PrescriptionSection
            consultationForm={consultationForm}
            setConsultationForm={setConsultationForm}
            thisCase={thisCase}
            setCase={setCase}
            userInfo={userInfo}
            register={register}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
          />
          <hr className={styles.ohrHr} />
          {consultationForm.prescriptions.length > 0 && (
            <>
              <div className={styles.subjective}>
                <span className={styles.subjectiveSpan}>Pharmacy</span>
              </div>
              <PharmacySection
                consultationForm={consultationForm}
                setConsultationForm={setConsultationForm}
                thisCase={thisCase}
                thisUser={thisCase.patient}
                setCase={setCase}
                medicalHistoryForm={formValues.medicalHistory}
                loading={loading}
                dependentId={thisCase.patient.guardian_id ? Number(thisCase.patient_id) : undefined}
              />
              <hr className={styles.ohrHr} />
            </>
          )}

          <div className={styles.subjective}>
            <span className={styles.subjectiveSpan}>Follow-up Care</span>
          </div>
          <FollowUpSection
            consultationForm={consultationForm}
            setConsultationForm={setConsultationForm}
            thisCase={thisCase}
            userInfo={userInfo}
            register={register}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
          />
        </>
      )}
    </>
  );
};

export default React.memo(PossibleTreatmentNeedsSection);
