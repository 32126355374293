import { client } from '../client';
import { CareCoordinationStatus, ICase } from './types/ICase';

export type CasePayload = {
  type?: string;
  status?: string;
  patient_id?: number;
  organization_id?: string;
  provider_id?: number;
  purpose?: string[] | null;
  purpose_other?: string | null;
  in_pain?: boolean;
  pain_description?: string[] | null;
  pain_level?: string | null;
  pain_time?: string | null;
  additional_info?: string | null;
  video_conference_date?: string | null;
  video_conference_other_participants?: string | null;
  payment_profile_id?: number | null;
  patient_current_state?: string;
  insurance_id?: number | null;
  second_insurance_id?: number | null;
  cdts?: string[] | null;
  dependent_id?: number | null;
  is_term_accepted?: boolean;
  tz?: string | null;
  license_states?: string[] | null;
  follow_up_status?: string;
};

export const createNewCase = async (payload: CasePayload): Promise<ICase> => {
  const { data } = await client.post<ICase>('/cases', payload);

  return data;
};
