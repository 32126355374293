import CustomDropdown from '@brands/Components/Inputs/CustomDropdown/CustomDropdown';
import TextArea from '@brands/Components/Inputs/TextArea/TextArea';
import { ICase } from '@brands/services/cases/types/ICase';
import { UserProfile } from '@brands/services/identity/types/UserProfile';
import { ObjectType, updateOneReportObject } from '@brands/services/oneReport/updateOneReportObject';
import { consultationPharmacySectionSelectStyles } from '@brands/Utils/customStyles';
import { displayErrorDetails } from '@brands/Utils/displayError';
import { checkCollapsibleHeaderColor } from '@brands/Utils/getCollapsibleHeader';
import {
  careCoordinationTypeOptions,
  careCoordinationUrgencyOptions,
  Option,
  oralHealthCoachingTypeOptions,
} from '@brands/Utils/selectOptions';
import React, { useRef, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { FollowUp, OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';

type FollowUpSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const FollowUpSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  userInfo,
  register,
  setValue,
  trigger,
  errors,
}: FollowUpSectionProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isCareCoordinationSelected, setIsCareCoordinationSelected] = useState(
    consultationForm?.followUp?.care_coordination || false,
  );
  const [isOralHealthCoachingSelected, setIsOralHealthCoachingSelected] = useState(
    consultationForm?.followUp?.oral_health_coaching || false,
  );

  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [isOtherSelectedOH, setIsOtherSelectedOH] = useState(false);

  const updateFollowUp = async (id: string, nonEmptyPayload: FollowUp): Promise<void> => {
    try {
      const res = await updateOneReportObject(thisCase?.one_report.id || '', ObjectType.FollowUp, {
        ...nonEmptyPayload,
        id,
      });
      setConsultationForm({
        ...consultationForm,
        followUp: res.follow_ups,
      });
    } catch (error) {
      displayErrorDetails(error);
    }
  };

  const handleFollowUpOnBlur = async (followUp: FollowUp): Promise<void> => {
    const id = thisCase.one_report.follow_ups.id || '1';
    if (id) {
      const nonEmptyPayload = {
        ...(followUp.care_coordination !== null
          ? { care_coordination: followUp.care_coordination }
          : { care_coordination: false }),
        ...(followUp.oral_health_coaching
          ? { oral_health_coaching: followUp.oral_health_coaching }
          : { oral_health_coaching: false }),
        ...(followUp.care_coordination_type
          ? { care_coordination_type: followUp.care_coordination_type }
          : { care_coordination_type: '' }),
        ...(followUp.care_coordination_urgency
          ? { care_coordination_urgency: followUp.care_coordination_urgency }
          : { care_coordination_urgency: '' }),
        ...(followUp.care_coordination_reason
          ? { care_coordination_reason: followUp.care_coordination_reason }
          : { care_coordination_reason: '' }),

        ...(followUp.oral_health_coaching_type
          ? { oral_health_coaching_type: followUp.oral_health_coaching_type }
          : { oral_health_coaching_type: '' }),
        ...(followUp.oral_health_coaching_urgency
          ? { oral_health_coaching_urgency: followUp.oral_health_coaching_urgency }
          : { oral_health_coaching_urgency: '' }),
        ...(followUp.oral_health_coaching_reason
          ? { oral_health_coaching_reason: followUp.oral_health_coaching_reason }
          : { oral_health_coaching_reason: '' }),
      };

      await updateFollowUp(id, nonEmptyPayload);
    }
  };

  return (
    <div className={styles.FollowUpSection}>
      <div className={`${styles.FollowUpSectionDiv} form-check m-0`}>
        <input
          {...register('followUp.care_coordination')}
          className="form-check-input"
          type="checkbox"
          id="care_coordination"
          checked={consultationForm?.followUp?.care_coordination}
          onChange={() => {
            const isChecked = !isCareCoordinationSelected;

            setValue('followUp.care_coordination', isChecked, { shouldValidate: true });
            setIsCareCoordinationSelected(isChecked);
            if (isChecked) {
              setConsultationForm((prevState) => ({
                ...prevState,
                followUp: {
                  ...prevState.followUp,
                  care_coordination: isChecked,
                },
              }));
              handleFollowUpOnBlur({
                ...consultationForm.followUp,
                care_coordination: isChecked,
              });
            } else {
              setConsultationForm((prevState) => ({
                ...prevState,
                followUp: {
                  ...prevState.followUp,
                  care_coordination: isChecked,
                  care_coordination_type: '',
                  care_coordination_reason: '',
                  care_coordination_urgency: '',
                },
              }));
              handleFollowUpOnBlur({
                ...consultationForm.followUp,
                care_coordination: isChecked,
                care_coordination_type: '',
                care_coordination_reason: '',
                care_coordination_urgency: '',
              });
            }
          }}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
        <label className={`form-check-label ${styles.additionalPeopleCheckLabel}`} htmlFor="isAdditionalPeople">
          Dentistry.One - Care Coordination
        </label>
      </div>
      {isCareCoordinationSelected && (
        <div className={styles.followUpInputGroup}>
          <div className={styles.followUpInputGroupDiv} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Type*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`followUp.care_coordination_type`)}
                  styles={consultationPharmacySectionSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={careCoordinationTypeOptions}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={
                    careCoordinationTypeOptions.find(
                      (option: Option) => option.value === consultationForm.followUp.care_coordination_type,
                    )
                      ? careCoordinationTypeOptions.find(
                          (option: Option) => option.value === consultationForm.followUp.care_coordination_type,
                        )
                      : consultationForm?.followUp?.care_coordination_type?.trim() === ''
                      ? ''
                      : { value: 'other', label: 'Other' }
                  }
                  onChange={(value: any) => {
                    if (value) {
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          care_coordination_type: value.value,
                        },
                      }));
                      setIsOtherSelected(value.value === 'other');
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        care_coordination_type: value.value,
                      });
                    }
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  isConsultationPharmacySection
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            {(isOtherSelected ||
              !careCoordinationTypeOptions.some(
                (option: Option) => option.value === consultationForm.followUp.care_coordination_type,
              )) &&
              consultationForm.followUp.care_coordination_type.trim() !== '' && (
                <div className={styles.selectInput}>
                  <TextArea
                    {...register(`followUp.care_coordination_other_type`)}
                    wrapperStyle={{
                      marginTop: '0',
                      borderBottom: 'none',
                      minHeight: '30px',
                      height: 'auto',
                    }}
                    inputClass={styles.input}
                    placeholder=""
                    isUnderlined={false}
                    defaultValue={
                      consultationForm.followUp.care_coordination_type !== 'other'
                        ? consultationForm.followUp.care_coordination_type
                        : ''
                    }
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const { value } = e.target;
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          care_coordination_type: value,
                        },
                      }));
                      setValue(`followUp.care_coordination_other_type`, value, { shouldValidate: true });
                      if (errors.prescriptions) trigger('prescriptions');
                    }}
                    onBlur={(e: any) => {
                      const { value } = e.target;
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        care_coordination_type: value,
                      });
                    }}
                    disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  />
                </div>
              )}
          </div>
          <div className={styles.followUpInputGroupDiv}>
            {' '}
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Urgency*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`followUp.care_coordination_urgency`)}
                  styles={consultationPharmacySectionSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={careCoordinationUrgencyOptions}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={careCoordinationUrgencyOptions.find(
                    (option: Option) => option.value === consultationForm.followUp.care_coordination_urgency,
                  )}
                  onChange={(value: any) => {
                    if (value) {
                      setValue('followUp.care_coordination_urgency', value.value, {
                        shouldValidate: true,
                      });
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          care_coordination_urgency: value.value,
                        },
                      }));
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        care_coordination_urgency: value.value,
                      });
                    }
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  isConsultationPharmacySection
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
          <div className={styles.followUpInputGroupDiv}>
            {' '}
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Reason*</div>
              <div className={styles.input}>
                <TextArea
                  {...register(`followUp.care_coordination_reason`)}
                  wrapperStyle={{
                    marginTop: '0',
                    borderBottom: 'none',
                    minHeight: '30px',
                    height: 'auto',
                  }}
                  inputClass={styles.input}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={consultationForm.followUp.care_coordination_reason ?? ''}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    setConsultationForm((prevState) => ({
                      ...prevState,
                      followUp: {
                        ...prevState.followUp,
                        care_coordination_reason: value,
                      },
                    }));
                    setValue(`followUp.care_coordination_reason`, value!, { shouldValidate: true });
                    if (errors.prescriptions) trigger('prescriptions');
                  }}
                  onBlur={(e: any) => {
                    const { value } = e.target;
                    handleFollowUpOnBlur({
                      ...consultationForm.followUp,
                      care_coordination_reason: value,
                    });
                  }}
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.FollowUpSectionDiv} form-check m-0`}>
        <input
          {...register('followUp.oral_health_coaching')}
          className="form-check-input"
          type="checkbox"
          id="oral_health_coaching"
          checked={consultationForm?.followUp?.oral_health_coaching}
          onChange={() => {
            const isChecked = !isOralHealthCoachingSelected;

            setValue('followUp.oral_health_coaching', isChecked, { shouldValidate: true });
            setIsOralHealthCoachingSelected(isChecked);
            if (isChecked) {
              setConsultationForm((prevState) => ({
                ...prevState,
                followUp: {
                  ...prevState.followUp,
                  oral_health_coaching: isChecked,
                },
              }));
              handleFollowUpOnBlur({
                ...consultationForm.followUp,
                oral_health_coaching: isChecked,
              });
            } else {
              setConsultationForm((prevState) => ({
                ...prevState,
                followUp: {
                  ...prevState.followUp,
                  oral_health_coaching: isChecked,
                  oral_health_coaching_reason: '',
                  oral_health_coaching_type: '',
                  oral_health_coaching_urgency: '',
                },
              }));
              handleFollowUpOnBlur({
                ...consultationForm.followUp,
                oral_health_coaching: isChecked,
                oral_health_coaching_reason: '',
                oral_health_coaching_type: '',
                oral_health_coaching_urgency: '',
              });
            }
          }}
          disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
        />
        <label className={`form-check-label ${styles.additionalPeopleCheckLabel}`} htmlFor="isAdditionalPeople">
          Dentistry.One - Oral Health Coaching
        </label>
      </div>
      {isOralHealthCoachingSelected && (
        <div className={styles.followUpInputGroup}>
          <div className={styles.followUpInputGroupDiv} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Type*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`followUp.oral_health_coaching_type`)}
                  styles={consultationPharmacySectionSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={oralHealthCoachingTypeOptions}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={
                    oralHealthCoachingTypeOptions.find(
                      (option: Option) => option.value === consultationForm.followUp.oral_health_coaching_type,
                    )
                      ? oralHealthCoachingTypeOptions.find(
                          (option: Option) => option.value === consultationForm.followUp.oral_health_coaching_type,
                        )
                      : consultationForm.followUp.oral_health_coaching_type.trim() === ''
                      ? ''
                      : { value: 'other', label: 'Other' }
                  }
                  onChange={(value: any) => {
                    if (value) {
                      setValue('followUp.oral_health_coaching_type', value.value, {
                        shouldValidate: true,
                      });
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          oral_health_coaching_type: value.value,
                        },
                      }));
                      setIsOtherSelectedOH(value.value === 'other');
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        oral_health_coaching_type: value.value,
                      });
                    }
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  isConsultationPharmacySection
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
            {(isOtherSelectedOH ||
              !oralHealthCoachingTypeOptions.some(
                (option: Option) => option.value === consultationForm.followUp.oral_health_coaching_type,
              )) &&
              consultationForm.followUp.oral_health_coaching_type.trim() !== '' && (
                <div className={styles.selectInput}>
                  <TextArea
                    {...register(`followUp.oral_health_coaching_other_type`)}
                    wrapperStyle={{
                      marginTop: '0',
                      borderBottom: 'none',
                      minHeight: '30px',
                      height: 'auto',
                    }}
                    inputClass={styles.input}
                    placeholder=""
                    isUnderlined={false}
                    defaultValue={
                      consultationForm.followUp.oral_health_coaching_type !== 'other'
                        ? consultationForm.followUp.oral_health_coaching_type
                        : ''
                    }
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const { value } = e.target;
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          oral_health_coaching_type: value,
                        },
                      }));
                      setValue(`followUp.oral_health_coaching_other_type`, value, { shouldValidate: true });
                      if (errors.prescriptions) trigger('prescriptions');
                    }}
                    onBlur={(e: any) => {
                      const { value } = e.target;
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        oral_health_coaching_type: value,
                      });
                    }}
                    disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  />
                </div>
              )}
          </div>
          <div className={styles.followUpInputGroupDiv}>
            {' '}
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Urgency*</div>
              <div className={styles.input}>
                <CustomDropdown
                  {...register(`followUp.oral_health_coaching_urgency`)}
                  styles={consultationPharmacySectionSelectStyles}
                  placeholder=""
                  menuPortalTarget={document.body}
                  menuPlacement="auto"
                  options={careCoordinationUrgencyOptions}
                  closeMenuOnScroll={(e: any) => e.target.contains(containerRef.current)}
                  value={careCoordinationUrgencyOptions.find(
                    (option: Option) => option.value === consultationForm.followUp.oral_health_coaching_urgency,
                  )}
                  onChange={(value: any) => {
                    if (value) {
                      setValue('followUp.oral_health_coaching_urgency', value.value, {
                        shouldValidate: true,
                      });
                      setConsultationForm((prevState) => ({
                        ...prevState,
                        followUp: {
                          ...prevState.followUp,
                          oral_health_coaching_urgency: value.value,
                        },
                      }));
                      handleFollowUpOnBlur({
                        ...consultationForm.followUp,
                        oral_health_coaching_urgency: value.value,
                      });
                    }
                  }}
                  isDisabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                  isConsultationPharmacySection
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
          <div className={styles.followUpInputGroupDiv}>
            {' '}
            <div className={`${styles.selectInput}`}>
              <div className={styles.title}>Reason*</div>
              <div className={styles.input}>
                <TextArea
                  {...register(`followUp.oral_health_coaching_reason`)}
                  wrapperStyle={{
                    marginTop: '0',
                    borderBottom: 'none',
                    minHeight: '30px',
                    height: 'auto',
                  }}
                  inputClass={styles.input}
                  placeholder=""
                  isUnderlined={false}
                  defaultValue={consultationForm.followUp.oral_health_coaching_reason ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = e.target;
                    setConsultationForm((prevState) => ({
                      ...prevState,
                      followUp: {
                        ...prevState.followUp,
                        oral_health_coaching_reason: value,
                      },
                    }));
                    setValue(`followUp.oral_health_coaching_reason`, value, { shouldValidate: true });
                    if (errors.prescriptions) trigger('prescriptions');
                  }}
                  onBlur={(e: any) => {
                    const { value } = e.target;
                    handleFollowUpOnBlur({
                      ...consultationForm.followUp,
                      oral_health_coaching_reason: value,
                    });
                  }}
                  disabled={checkCollapsibleHeaderColor(thisCase || '', userInfo, '') !== 'edit'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(FollowUpSection);
