/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';

import { ICase } from '../../../services/cases/types/ICase';
import { UserRoleName } from '../../../services/identity/types/UserProfile';
import { Prescription, Treatment } from './types';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { checkFutureDate, checkValidDate, isValidDate, isValidZipCode } from '@brands/Utils/validation';
import { IPrescription } from '@brands/services/oneReport/types/ITreatments';

export const evaluationCodes = [
  'D0120',
  'D0140',
  'D0145',
  'D0150',
  'D0160',
  'D0170',
  'D0171',
  'D0180',
  'D0190',
  'D0191',
];

export const validationSchema = (userInfo: any, thisCase: ICase, addRequiredField: boolean): any => {
  const checkAllFieldsFilled = (treatment: Treatment): boolean => {
    if (treatment) {
      const { category, tooth, description, urgency } = treatment;
      if (tooth.value && category.value && description.trim() !== '' && urgency.value) {
        return true;
      }
    }
    return false;
  };

  const checkAllPrescriptionFieldsFilled = (prescription: Prescription): boolean => {
    if (prescription) {
      const { drug, sig, prescription_date, refills, dispense } = prescription;
      if (
        drug?.trim() !== '' &&
        sig?.trim() !== '' &&
        prescription_date &&
        prescription_date.trim() !== '' &&
        isValidDate(prescription_date) &&
        refills?.trim() !== '' &&
        dispense?.trim() !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  return Yup.object({
    checkboxes:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.array().test(
            'all-fields-filled',
            'Failed to check one or more check boxes. Please update to submit your Oral Health Report',
            (checkboxes = []) => {
              if (checkboxes.length === 0) {
                return true;
              }
              const isFilled = checkboxes.some((checkbox) => !checkbox.isSelected);
              return !isFilled;
            },
          )
        : Yup.array().of(
            Yup.object({
              value: Yup.string(),
              label: Yup.string(),
              isSelected: Yup.boolean(),
            }),
          ),
    isAdditionalPeople: Yup.boolean(),
    additionalPeople: Yup.string().when('isAdditionalPeople', {
      is: true,
      then: Yup.string().required('This field is required to be filled because you have selected yes'),
    }),
    selectedImages: Yup.array().nullable(),
    cdt:
      (userInfo.role.name === UserRoleName.CareAdvisor ||
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin) &&
      (thisCase?.status === 'accepted_care_advisor' || thisCase?.status === 'in_progress_care_advisor')
        ? Yup.array().nullable()
        : Yup.array()
            .test('one-evaluation-code', 'Please enter one evaluation CDT code', (value) => {
              if (!value || value.length === 0) {
                return false;
              }
              const hasEvaluationCode = value.some((code) => evaluationCodes.includes(code));
              return hasEvaluationCode;
            })
            .required('CDT is required'),
    assessment:
      (userInfo.role.name === UserRoleName.CareAdvisor ||
        userInfo.role.name === UserRoleName.Admin ||
        userInfo.role.name === UserRoleName.SuperAdmin ||
        userInfo.role.name === UserRoleName.OrganizationAdmin) &&
      (thisCase?.status === 'accepted_care_advisor' || thisCase?.status === 'in_progress_care_advisor')
        ? Yup.array().nullable()
        : Yup.string().trim().required('Please fill the assessment field'),
    objective:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.object().shape({
            is_media_utilized: Yup.string().required('Objective: Photos, Videos, Radiographs is required'),
            is_intra_oral_utilized: Yup.string().required('Objective: Intra-Oral is required'),
            is_extra_oral_utilized: Yup.string().required('Objective: Extra-Oral is required'),
          })
        : Yup.object().shape({
            is_media_utilized: Yup.string(),
            is_intra_oral_utilized: Yup.string(),
            is_extra_oral_utilized: Yup.string(),
          }),
    plan:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.object().shape({
            disposition: Yup.string()
              .trim()
              .required('Plan: Disposition/Intervention or recommendations provided is required'),
            prescription_in_icore: Yup.mixed().nullable(),
            pharmacy: Yup.mixed().nullable(),
            need_for_oral_health_coaching: Yup.mixed().nullable(),
          })
        : Yup.object().shape({
            disposition: Yup.string(),
            prescription_in_icore: Yup.mixed().nullable(),
            pharmacy: Yup.mixed().nullable(),
            need_for_oral_health_coaching: Yup.mixed().nullable(),
          }),
    prescriptions:
      addRequiredField && userInfo.role.name === UserRoleName.Provider
        ? Yup.array()
            .of(
              Yup.object().shape({
                drug: Yup.string().required('Drug is required'),
                sig: Yup.string().required('Sig is required'),
                prescription_date: Yup.string()
                  .required('Prescription Date is required')
                  .test('test-valid-date', 'Enter a valid date in MM/DD/YYYY format', (value) => {
                    if (value) {
                      const [month, day, year] = value.split('/');
                      if (!month || !day || !year) return false; // Ensure MM/DD/YYYY format
                      if (!checkValidDate(month, day, year)) return false; // Validate date with leap year
                    }
                    return true;
                  }),
                refills: Yup.string().required('Refills is required'),
                dispense: Yup.string().required('Dispense is required'),
              }),
            )
            .required('At least one prescription is required')
        : Yup.array().nullable(),
    pharmacies: Yup.object()
      .nullable()
      .when('prescriptions', {
        is: (prescriptions: IPrescription[]) => Array.isArray(prescriptions) && prescriptions.length > 0,
        then: Yup.object().shape({
          pharmacy_name: Yup.string().trim().required('Plan: Pharmacy name is required'),
          pharmacy_street_address: Yup.string().trim().required('Plan: Pharmacy street address is required'),
          pharmacy_city: Yup.string().trim().required('Plan: Pharmacy city is required'),
          pharmacy_state: Yup.string().trim().required('Plan: Pharmacy state is required'),
          pharmacy_zip_code: Yup.string()
            .trim()
            .required('Plan: Pharmacy zipcode is required')
            .test('is-valid-zip', 'Plan: Invalid pharmacy zip code', (value) => (value ? isValidZipCode(value) : true)),
          pharmacy_phone_number: Yup.string()
            .trim()
            .required('Plan: Pharmacy phone number is required')
            .test('is-valid-phone', 'Plan: Invalid phone number', (value) =>
              value ? isValidPhoneNumber(value, { defaultCountry: 'US' }) : true,
            ),
        }),
        otherwise: Yup.object()
          .nullable()
          .shape({
            pharmacy_name: Yup.string().nullable(),
            pharmacy_street_address: Yup.string().nullable(),
            pharmacy_city: Yup.string().nullable(),
            pharmacy_state: Yup.string().nullable(),
            pharmacy_zip_code: Yup.string().nullable(),
            pharmacy_phone_number: Yup.string()
              .nullable()
              .test('is-valid-phone', 'Plan: Invalid pharmacy phone number', (value) =>
                value ? isValidPhoneNumber(value, { defaultCountry: 'US' }) : true,
              ),
          }),
      }),
    followUp:
      addRequiredField && userInfo.role.name === UserRoleName.Provider
        ? Yup.object()
            .nullable()
            .shape({
              care_coordination: Yup.boolean(),
              oral_health_coaching: Yup.boolean(),

              care_coordination_type: Yup.string().when('care_coordination', {
                is: true,
                then: Yup.string().required('Care coordination type is required'),
              }),
              care_coordination_urgency: Yup.string().when('care_coordination', {
                is: true,
                then: Yup.string().required('Care coordination urgency is required'),
              }),
              care_coordination_reason: Yup.string().when('care_coordination', {
                is: true,
                then: Yup.string().required('Care coordination reason is required'),
              }),
              care_coordination_other_type: Yup.string().when('care_coordination_type', {
                is: 'other',
                then: Yup.string()
                  .trim()
                  .required('Care coordination other type is required')
                  .notOneOf([''], 'Care coordination other type cannot be an empty string'),
              }),

              oral_health_coaching_type: Yup.string().when('oral_health_coaching', {
                is: true,
                then: Yup.string().required('Oral health coaching type is required'),
              }),
              oral_health_coaching_urgency: Yup.string().when('oral_health_coaching', {
                is: true,
                then: Yup.string().required('Oral health coaching urgency is required'),
              }),
              oral_health_coaching_reason: Yup.string().when('oral_health_coaching', {
                is: true,
                then: Yup.string().required('Oral health coaching reason is required'),
              }),
              oral_health_coaching_other_type: Yup.string().when('oral_health_coaching_type', {
                is: 'other',
                then: Yup.string()
                  .trim()
                  .required('Oral health coaching  other type is required')
                  .notOneOf([''], 'Oral health coaching  other type cannot be an empty string'),
              }),
            })
        : Yup.object().nullable(),
    treatments:
      userInfo.role.name === UserRoleName.Provider
        ? Yup.array().test('all-fields-filled', 'Please complete all treatment fields', (treatments = []) => {
            if (treatments.length === 0) {
              return true;
            }
            const emptyRow = treatments.some((treatment) => {
              const { category, tooth, urgency, description } = treatment;
              return (
                category?.value === '' && tooth?.value === '' && urgency?.value === '' && description?.trim() === ''
              );
            });
            return emptyRow ? false : treatments.every((treatment) => checkAllFieldsFilled(treatment));
          })
        : Yup.array().of(
            Yup.object({
              category: Yup.object(),
              tooth: Yup.object(),
              urgency: Yup.object(),
              description: Yup.string().trim(), // Added trim() for description
              toothArea: Yup.object(),
            }),
          ),
    educations:
      userInfo.role.name === UserRoleName.Provider || userInfo.role.name === UserRoleName.CareAdvisor
        ? Yup.array().test('all-fields-filled', 'Please complete all educations fields', (educations = []) => {
            if (educations.length === 0) {
              return true;
            }

            const isFilled = educations.some((education) => {
              const { url, title } = education;
              return url.trim() === '' || title.trim() === '';
            });

            return !isFilled;
          })
        : Yup.array().of(
            Yup.object({
              url: Yup.string(),
              title: Yup.string(),
            }),
          ),
    caseNotes: Yup.string(),
  });
};
