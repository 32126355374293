/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import PatientSelector from '@brands/Components/Inputs/SelectPatientInptWithPicture/SelectPatientInputWithPicture';
import SmileScanMobile from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_star.svg';
import SmileScanMobileWhite from '@brands/Dashboard/Dashboard/Patient/utils/smile_scan_star_white.svg';
import { selectAuth } from '@brands/store/selectors/auth';
import { selectCurrentPatientState } from '@brands/store/selectors/currentPatientState';
import { selectDependents } from '@brands/store/selectors/dependents';
import { selectPatientForm } from '@brands/store/selectors/patientForm';
import { getTextForStatus } from '@brands/Utils/getTextForStatus';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TbMessage2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import Button from '../../../Components/Button/Button';
import Container from '../../../Components/Container/Container';
import { Loading } from '../../../Components/LoadingSpinner/Loading';
import { useSubscribe, useWindowSize } from '../../../hooks';
import useCases from '../../../hooks/useCases';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { Subscriber } from '../../../hooks/useSubscribe';
import { CaseType, ICase, Status } from '../../../services/cases/types/ICase';
import {
  getCaseDate,
  getCaseTime,
  getFormatDate,
  getFormattedDateSmileScan,
  getFormattedTimeSmileScan,
} from '../../../Utils/formatDate';
import { getFriendlyNameOfConsultationType } from '../../../Utils/getFriendlyName';
import { sortData, SortKeys, SortOrder } from '../../../Utils/sortData';
import report from '../../Dashboard/CareAdvisor/assets/oneReportWhite.svg';
import ProfileComponent from '../../Dashboard/CareAdvisor/Component/ProfileComponent';
import UpcomingAppointments from './AppointmentCard/upcomingAppointments';
import styles from './appointments.module.scss';

type AppointmentsProps = {
  isProfileCard?: boolean;
};

const Appointments = ({ isProfileCard }: AppointmentsProps): JSX.Element => {
  const screenSize = useWindowSize();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { userInfo } = useAppSelector(selectAuth);
  const { selectedPatient } = useAppSelector(selectPatientForm);
  const { dependents: listOfDependents } = useAppSelector(selectDependents);
  const currentState = useAppSelector(selectCurrentPatientState);
  const [isLoading, setIsLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const lastMyCasesItemRef = useRef<HTMLTableRowElement>(null);
  const [appointments, setAppointments] = useState<ICase[]>([]);
  const [params, setParams] = useState({
    sort_by: 'created_at' as const,
    sort_direction: 'desc' as const,
    patient_id: Number(selectedPatient?.value || userInfo.id),
    status: ['canceled', 'completed'],
    include_patient_care_advisor_conversation: true,
    include_patient_provider_conversation: true,
  });
  useEffect(() => {
    if (selectedPatient) {
      const updatedParams = {
        sort_by: 'created_at' as const,
        sort_direction: 'desc' as const,
        patient_id: Number(selectedPatient.value),
        status: ['canceled', 'completed'],
        include_patient_care_advisor_conversation: true,
        include_patient_provider_conversation: true,
      };
      setParams(updatedParams);
    }
  }, [selectedPatient]);

  const { cases, dataLoading, reloadAll, loadNextPage } = useCases(params, true);
  const subscriber: Subscriber = useMemo(
    () => ({
      onMessage: (message: Record<string, any>) => {
        if (
          [
            'case_updated',
            'case_new_message_created',
            'case_new_conversation_created',
            'case_cancelled',
            'case_completed',
          ].includes(message.command)
        ) {
          reloadAll();
        }
      },
      commands: [
        'case_updated',
        'case_new_message_created',
        'case_new_conversation_created',
        'case_cancelled',
        'case_completed',
      ],
    }),

    [reloadAll],
  );
  const { subscribe, unsubscribe } = useSubscribe();

  const loadMore = (): void => {
    setIsLoading(true);
    loadNextPage().then(() => {
      setIsLoading(false);
    });
  };

  const handleIntersection = (entries: IntersectionObserverEntry[]): void => {
    const lastItem = entries[0];
    if (lastItem.isIntersecting && !isLoading) {
      loadMore();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(handleIntersection);

      const currentRef = lastMyCasesItemRef.current;
      if (currentRef) {
        observer.current.observe(currentRef);
      }
    }, 100);

    return () => {
      clearTimeout(timer);
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [cases]);

  useEffect(() => {
    const subscriptionId = subscribe(subscriber);

    return () => unsubscribe(subscriptionId);
  }, [subscribe, unsubscribe, subscriber]);

  useEffect(() => {
    setAppointments(cases);
  }, [cases]);

  const navigate = useNavigate();

  const [sortKey] = useState<SortKeys>('created_at');
  const [sortOrder] = useState<SortOrder>('desc');

  const sortedData = useCallback(
    () => sortData({ tableData: appointments, sortKey, reverse: sortOrder === 'desc', userRole: userInfo.role.name }),
    [appointments, sortKey, sortOrder],
  );

  return (
    <Container
      childClassName={styles.appointments}
      isBGEclipse={false}
      containerStyle={{
        justifyContent: isProfileCard ? 'start' : '',
        alignItems: isProfileCard ? 'start' : '',
        width: isProfileCard ? '100%' : '',
      }}
      isProfileCard={isProfileCard}
    >
      <div
        className={styles.container}
        style={{
          marginTop: isProfileCard ? '0px' : '',
          paddingLeft: isProfileCard && screenSize.width >= 992 ? '6rem' : '',
        }}
      >
        {listOfDependents.length > 0 && (
          <div className={`${styles.innerContainer} mb-1`}>
            <div className={styles.choosePatientContainer}>
              <PatientSelector name="service_for" activeOnly={false} />
            </div>
          </div>
        )}
        <UpcomingAppointments
          isProfileCard={isProfileCard}
          refreshAll={reloadAll}
          selectedPatientID={Number(selectedPatient?.value)}
        />
        <div className={`${styles.innerContainer} mb-1`}>
          <div className={styles.mainTitle} style={{ display: isProfileCard ? 'none' : '' }}>
            Completed
          </div>
          {!dataLoading ? (
            appointments.filter((appointment) => appointment.type !== 'messaging').length > 0 ? (
              <>
                {sortedData().map((appointment, index, array) => {
                  const isLastItem = index === array.length - 1;
                  return (
                    <div className={styles.appointmentDivContainer} ref={isLastItem ? lastMyCasesItemRef : null}>
                      <div className={styles.appointmentLeftSide}>
                        <div className={styles.appointmentDetails}>
                          <div className={styles.providerImage}>
                            {appointment.type !== CaseType.smile_scan ? (
                              appointment.type !== CaseType.care_coordination ? (
                                <ProfileComponent
                                  caseRow={appointment}
                                  displayRole={false}
                                  role="Provider"
                                  showName={false}
                                />
                              ) : (
                                <ProfileComponent
                                  caseRow={appointment}
                                  displayRole={false}
                                  role="CA"
                                  showName={false}
                                />
                              )
                            ) : (
                              <img className={styles.smileScan} src={SmileScanMobile} alt="SmileScan" />
                            )}
                          </div>
                          <div className={styles.appointmentInfo}>
                            <span
                              className={`${styles.appointmentState} ${
                                appointment.type === CaseType.smile_scan ? styles.smileScanAppointmentState : ''
                              }`}
                            >
                              {(appointment.provider_id || appointment.type === CaseType.care_coordination) &&
                                getFriendlyNameOfConsultationType(
                                  appointment.type,
                                  appointment.status === Status.Canceled || appointment.status === Status.Completed,
                                )}
                            </span>
                            {appointment.provider && (
                              <span className={styles.providerName}>
                                <ProfileComponent
                                  caseRow={appointment}
                                  displayRole={false}
                                  role="Provider"
                                  showImage={false}
                                  maxChars={25}
                                />
                              </span>
                            )}
                            {appointment.type === CaseType.care_coordination && (
                              <span className={styles.providerName}>
                                <ProfileComponent
                                  caseRow={appointment}
                                  displayRole={false}
                                  role="CA"
                                  showImage={false}
                                  maxChars={25}
                                />
                              </span>
                            )}
                            <span
                              className={styles.appointmentState}
                              style={{ color: appointment.status === Status.Completed ? 'green' : 'red' }}
                            >
                              {getTextForStatus(userInfo.role.name, appointment.type, appointment.status)}
                            </span>
                            {(appointment.type === CaseType.video_call_scheduled ||
                              appointment.type === CaseType.video_call_instant ||
                              appointment.type === CaseType.phone_call_scheduled) &&
                              appointment.video_conference_date && (
                                <span className={styles.appointmentVideoDate}>
                                  <span>{getCaseDate(appointment.video_conference_date || '', timeZone)}</span>
                                  <span>{getCaseTime(appointment.video_conference_date || '', timeZone)}</span>
                                </span>
                              )}
                            {appointment.type !== CaseType.smile_scan ? (
                              <span className={styles.appointmentDetailsInfo}>
                                <span>Requested</span>
                                <span>{getFormatDate(appointment.created_at || '', timeZone)}</span>
                              </span>
                            ) : (
                              <>
                                <span className={styles.smileScanDate}>
                                  {getFormattedDateSmileScan(appointment.updated_at || '', timeZone)}
                                </span>
                                <span className={styles.smileScanDate}>
                                  {getFormattedTimeSmileScan(appointment.updated_at || '', timeZone)}
                                </span>
                              </>
                            )}
                            {appointment.type !== CaseType.care_coordination &&
                              appointment.type !== CaseType.smile_scan && (
                                <span className={`${styles.caNAme} ${styles.appointmentDetailsInfo}`}>
                                  <span>Care Advisor</span>
                                  <span>
                                    <ProfileComponent
                                      caseRow={appointment}
                                      role="CA"
                                      displayRole={false}
                                      showImage={false}
                                      maxChars={25}
                                    />
                                  </span>
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.appointmentRightSide}>
                        <div className={styles.appointmentControls}>
                          <div className={styles.controls}>
                            {appointment.status !== 'canceled' && appointment.type !== CaseType.care_coordination && (
                              <Button
                                className={styles.actionBtnCompleted}
                                type="button"
                                onClick={() => {
                                  appointment.type === CaseType.smile_scan
                                    ? navigate(`/smile-scan-report/${appointment?.id}`)
                                    : navigate(`/oral-health-report/${appointment?.id}`);
                                }}
                              >
                                {appointment.type === CaseType.smile_scan ? (
                                  <img
                                    style={{ fill: '#FFFFFF', width: '30px', height: 'auto' }}
                                    src={SmileScanMobileWhite}
                                    alt="SmileScan"
                                  />
                                ) : (
                                  <img className={styles.cReport} src={report} alt="video" />
                                )}

                                {appointment.type === CaseType.smile_scan ? 'View Results' : 'View Report'}
                              </Button>
                            )}
                            {(appointment.patient_care_advisor_conversation_sid ||
                              appointment.patient_provider_conversation_sid) && (
                              <Button
                                className={styles.appBtn}
                                type="button"
                                onClick={() => navigate(`/case-chat/${appointment?.id}`)}
                              >
                                <TbMessage2 /> Read Messages
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {isLoading && <Loading dotted />}
              </>
            ) : (
              <span className={styles.noData}>No data to display</span>
            )
          ) : (
            <span>
              <Loading dotted />
            </span>
          )}
        </div>
      </div>
    </Container>
  );
};
Appointments.defaultProps = {
  isProfileCard: false,
};
export default Appointments;
