/* eslint-disable prefer-destructuring */
import { ICDT } from '@brands/services/oneReport/types/IOneReport';
import { multiSelectCommonCustomStyles } from '@brands/Utils/customStyles';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { GoTrash } from 'react-icons/go';

import MultiselectInput from '../../../../Components/Inputs/MultiselectInput/MultiselectInput';
import { CaseType, ICase, Status } from '../../../../services/cases/types/ICase';
import { listCDTOptions } from '../../../../services/identity/listCDTOptions';
import { UserProfile, UserRoleName } from '../../../../services/identity/types/UserProfile';
import { updateOneReport } from '../../../../services/oneReport/updateOneReport';
import { displayErrorDetails } from '../../../../Utils/displayError';
import { checkCollapsibleHeaderColor } from '../../../../Utils/getCollapsibleHeader';
import { OptionCDT } from '../../../../Utils/selectOptions';
import { OptionCdts, OralHealthReportSubmit } from '../../assets/types';
import styles from '../../styles.module.scss';
import { evaluationCodes } from '../../assets/validationSchema';

type ServicesProvidedSectionProps = {
  consultationForm: OralHealthReportSubmit;
  setConsultationForm: React.Dispatch<React.SetStateAction<OralHealthReportSubmit>>;
  thisCase: ICase;
  setCase: React.Dispatch<SetStateAction<ICase>>;
  userInfo: UserProfile;
  register: UseFormRegister<OralHealthReportSubmit>;
  setValue: UseFormSetValue<OralHealthReportSubmit>;
  getValues: UseFormGetValues<OralHealthReportSubmit>;
  trigger: UseFormTrigger<OralHealthReportSubmit>;
  errors: FieldErrors<OralHealthReportSubmit>;
};

const ServicesProvidedSection = ({
  consultationForm,
  setConsultationForm,
  thisCase,
  setCase,
  userInfo,
  register,
  setValue,
  getValues,
  trigger,
  errors,
}: ServicesProvidedSectionProps): JSX.Element => {
  const [cdtOptions, setCdtOptions] = useState<OptionCdts[]>([]);
  const [originalCDTCodes, setOriginalCDTCodes] = useState<ICDT[]>([]);
  const [cdtOptionsToDisplay, setCdtOptionsToDisplay] = useState<OptionCDT[]>([]);
  const [selectedEvaluationCode, setSelectedEvaluationCode] = useState<string | null>(null);
  const [otherCodes, setOtherCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const saveCaseCDT = async (caseCDT: string[]): Promise<void> => {
    setLoading(true);
    const { cdts: res } = await updateOneReport(thisCase?.one_report.id || '', {
      cdts:
        caseCDT.length > 0
          ? caseCDT
          : thisCase?.type === 'video_call_scheduled' ||
            thisCase?.type === 'video_call_instant' ||
            thisCase?.type === CaseType.phone_call_scheduled
          ? ['D9995']
          : ['D9996'],
    });
    setConsultationForm({
      ...consultationForm,
      cdt: res.map((currentCDT) => currentCDT.code),
    });
    setCase({
      ...thisCase,
      one_report: {
        ...thisCase.one_report,
        cdts: res,
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (thisCase) {
      setCdtOptionsToDisplay(thisCase?.one_report?.cdts || []);
    }
  }, [thisCase]);

  const getFilteredOptions = useCallback(() => {
    return cdtOptions.map((option) => {
      if (evaluationCodes.includes(option.value)) {
        return {
          ...option,
          disabled: selectedEvaluationCode && option.value !== selectedEvaluationCode,
        };
      }
      return option;
    });
  }, [cdtOptions, evaluationCodes, selectedEvaluationCode]);

  useEffect(() => {
    const currentCdtCodes = consultationForm.cdt;
    const initialCode = evaluationCodes.find((code) => currentCdtCodes.includes(code));

    if (initialCode) {
      setSelectedEvaluationCode(initialCode);
    }

    const uniqueCdtCodes = Array.from(new Set(currentCdtCodes));
    const filteredOtherCodes = uniqueCdtCodes.filter(
      (code) => code !== 'D9995' && code !== 'D9996' && !evaluationCodes.includes(code),
    );

    setOtherCodes(filteredOtherCodes);
  }, [consultationForm.cdt, evaluationCodes, getValues('cdt'), thisCase.one_report.cdts]);

  const handleRemoveEvaluationCode = () => {
    if (selectedEvaluationCode) {
      const updatedCdt = consultationForm.cdt.filter((code) => code !== selectedEvaluationCode);

      setSelectedEvaluationCode(null);
      setConsultationForm({ ...consultationForm, cdt: updatedCdt });
      setValue('cdt', updatedCdt);

      saveCaseCDT(updatedCdt);
    }
  };

  const handleRemoveOtherCode = async (codeToRemove: string): Promise<void> => {
    const updatedCdtCodes = consultationForm.cdt.filter((code) => code !== codeToRemove);
    setValue('cdt', updatedCdtCodes);
    setOtherCodes(
      updatedCdtCodes.filter((code) => !evaluationCodes.includes(code) && code !== 'D9995' && code !== 'D9996'),
    );
    saveCaseCDT(updatedCdtCodes);
    await trigger('cdt');
  };

  useEffect(() => {
    const fetchCDTOptionsOnce = async () => {
      try {
        const data = await listCDTOptions();
        setOriginalCDTCodes(data);

        const transformedData = data
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((cdt) => ({
            value: cdt.code,
            label: `${cdt.code}: ${cdt.nomenclature}`,
            disabled: cdt.code === 'D9995' || cdt.code === 'D9996',
          }));

        setCdtOptions(transformedData);
      } catch (error) {
        displayErrorDetails(error);
      }
    };

    fetchCDTOptionsOnce();
  }, []);

  return (
    <div className={styles.assessmentDiv}>
      <div className={`${styles.cDT}`}>
        <div className={`${styles.cDTDiv}`}>
          <span>Select one evaluation code from the drop-down.</span>
        </div>
        <div className={`${styles.cDTDiv}`}>
          <span>Additionally, add other service codes provided and documented.</span>
        </div>

        {checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'edit' ||
        checkCollapsibleHeaderColor(thisCase || '', userInfo, 'Assessment') === 'editNotRequired' ? (
          <MultiselectInput
            {...register('cdt')}
            name="cdt"
            placeholder="Please add CDT"
            options={getFilteredOptions()}
            style={multiSelectCommonCustomStyles}
            containerClass={styles.inputWrapper}
            selectorClass={styles.selector}
            errors={errors}
            isDisabled={loading}
            isFromCDTServices
            selectedValue={(() => {
              let selectedCDTValues = consultationForm.cdt;
              if (selectedCDTValues.length === 0) {
                const getDefaultCDT = (): string[] => {
                  return thisCase?.type === 'video_call_scheduled' ||
                    thisCase?.type === 'video_call_instant' ||
                    thisCase?.type === CaseType.phone_call_scheduled
                    ? ['D9995']
                    : ['D9996'];
                };
                selectedCDTValues = getDefaultCDT();
              }
              const d9995Index = selectedCDTValues.indexOf('D9995');
              const d9996Index = selectedCDTValues.indexOf('D9996');

              if (d9995Index !== -1) {
                const temp = selectedCDTValues[d9995Index];
                selectedCDTValues[d9995Index] = selectedCDTValues[0];
                selectedCDTValues[0] = temp;
              } else if (d9996Index !== -1) {
                const temp = selectedCDTValues[d9996Index];
                selectedCDTValues[d9996Index] = selectedCDTValues[0];
                selectedCDTValues[0] = temp;
              }
              return selectedCDTValues.map((item: string) => {
                return { label: item, value: item };
              });
            })()}
            onChange={(value) => {
              if (value) {
                const isValueArray = Array.isArray(value);
                if (isValueArray && thisCase) {
                  const enforcedValue =
                    thisCase?.type === 'video_call_scheduled' ||
                    thisCase?.type === 'video_call_instant' ||
                    thisCase?.type === CaseType.phone_call_scheduled
                      ? 'D9995'
                      : 'D9996';

                  const selectedValues = value.map((item) => item.value);

                  if (!selectedValues.includes(enforcedValue)) {
                    selectedValues.push(enforcedValue);
                  }

                  const updatedValues = selectedValues.map((val) => ({
                    label: cdtOptions.find((opt) => opt.value === val)?.label || val,
                    value: val,
                  }));

                  const selectedOption = value[value.length - 1]?.value;

                  if (evaluationCodes.includes(selectedOption)) {
                    setSelectedEvaluationCode(selectedOption);

                    setCdtOptions((prevOptions) =>
                      prevOptions.map((option) =>
                        evaluationCodes.includes(option?.value) && option?.value !== selectedOption
                          ? { ...option, disabled: true }
                          : option,
                      ),
                    );
                  } else {
                    if (selectedEvaluationCode && !value.some((item) => item?.value === selectedEvaluationCode)) {
                      setSelectedEvaluationCode(null);
                      setCdtOptions(
                        originalCDTCodes.map((cdt) => ({
                          value: cdt.code,
                          label: `${cdt.code}: ${cdt.nomenclature}`,
                          disabled: cdt.code === 'D9995' || cdt.code === 'D9996',
                        })),
                      );
                    }

                    const newOtherCodes = Array.from(
                      new Set(
                        selectedValues.filter(
                          (code) =>
                            code !== 'D9995' &&
                            code !== 'D9996' &&
                            !evaluationCodes.includes(code) &&
                            !otherCodes.includes(code),
                        ),
                      ),
                    );

                    setOtherCodes((prevOtherCodes) => [
                      ...prevOtherCodes.filter((code) => selectedValues.includes(code)),
                      ...newOtherCodes,
                    ]);
                  }

                  setConsultationForm({
                    ...consultationForm,
                    cdt: updatedValues.map((item) => item.value),
                  });

                  setValue(
                    'cdt',
                    updatedValues.map((item) => item.value),
                    { shouldValidate: true },
                  );

                  const filteredValues = getFilteredOptions().filter((item) =>
                    updatedValues.map((i) => i.value).includes(item.value),
                  );
                  const convertedValueToOptionCDT = filteredValues.map((item) => ({
                    code: item.value,
                    nomenclature: item.label.split(': ')[1],
                  }));

                  setCase({
                    ...thisCase,
                    cdts: convertedValueToOptionCDT,
                    one_report: {
                      ...thisCase.one_report,
                      assessments: getValues('assessment') || '',
                    },
                  });

                  saveCaseCDT(updatedValues.map((item) => item.value));
                }
              }
            }}
            closeMenuOnSelect
          />
        ) : (
          <ul
            className={styles.cdtItem}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '1rem',
              marginBottom: '0rem',
            }}
          >
            {cdtOptionsToDisplay.map((item) => {
              return (
                <li key={item.code}>
                  {item.code}: {item.nomenclature}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className={styles.cdtChildren}>
        {
          <div className={styles.fixedValue}>
            <div className={styles.boldText}>
              {thisCase?.type === 'video_call_scheduled' ||
              thisCase?.type === 'video_call_instant' ||
              thisCase?.type === CaseType.phone_call_scheduled
                ? 'D9995: Teledentistry'
                : 'D9996: Teledentistry'}
              <span className={styles.noteText}> (Code cannot be deleted)</span>
            </div>
            <div>
              {thisCase?.type === 'video_call_scheduled' ||
              thisCase?.type === 'video_call_instant' ||
              thisCase?.type === CaseType.phone_call_scheduled
                ? 'synchronous; real-time encounter'
                : 'Asynchronous; information stored and forwarded to dentist for subsequent review'}
            </div>
          </div>
        }
        {selectedEvaluationCode && (
          <div className={styles.evaluationCodesDiv}>
            <div className={styles.evaluationCodes}>
              {userInfo.role.name === UserRoleName.Provider && thisCase.status === Status.InProgressProvider && (
                <GoTrash
                  className={styles.trashIcon}
                  onClick={handleRemoveEvaluationCode}
                  title="Remove this evaluation code"
                />
              )}
              <div className={styles.evaluationCodesInfo}>
                <div className={styles.boldText}>
                  {
                    originalCDTCodes.find(
                      (selectedEvaluationCodeValue) => selectedEvaluationCodeValue.code === selectedEvaluationCode,
                    )?.code
                  }
                </div>
                <div>
                  {
                    originalCDTCodes.find(
                      (selectedEvaluationCodeValue) => selectedEvaluationCodeValue.code === selectedEvaluationCode,
                    )?.nomenclature
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        {otherCodes.length > 0 &&
          otherCodes.map((otherCode) => (
            <div key={otherCode} className={styles.otherCodesDiv}>
              <div className={`${styles.otherCodes}`}>
                {userInfo.role.name === UserRoleName.Provider && thisCase.status === Status.InProgressProvider && (
                  <GoTrash
                    className={styles.trashIcon}
                    onClick={() => handleRemoveOtherCode(otherCode)}
                    title="Remove this other code"
                  />
                )}
                <div className={styles.evaluationCodesInfo}>
                  <div className={styles.boldText}>
                    {originalCDTCodes.find((code) => code.code === otherCode)?.code}
                  </div>
                  <div>{originalCDTCodes.find((code) => code.code === otherCode)?.nomenclature}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(ServicesProvidedSection);
