import { client } from '../client';
import {
  CareCoordinationReport,
  CreateCareCoordinationReportBenefitsNavigationParams,
  CreateCareCoordinationReportInternalNoteParams,
  CreateCareCoordinationReportNotesParams,
  ObjectType,
} from './types/types';

export const addCareCoordinationReportObject = async (
  care_coordination_report_id: string,
  object_type: ObjectType,
  payload:
    | CreateCareCoordinationReportInternalNoteParams
    | CreateCareCoordinationReportBenefitsNavigationParams
    | CreateCareCoordinationReportNotesParams,
): Promise<CareCoordinationReport> => {
  const { data } = await client.post<CareCoordinationReport>(
    `/care_advisor/care_coordination_reports/${care_coordination_report_id}/${object_type}`,
    payload,
  );

  return data;
};
