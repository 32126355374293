import { selectAuth } from '@brands/store/selectors/auth';
import { openModal } from '@brands/store/slices/modalSlice';
import { Option } from '@brands/Utils/selectOptions';
import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import ProfileImage from '../../../../../Components/ProfileImage/ProfileImage';
import { useAppSelector } from '../../../../../hooks/useReduxHook';
import { Language, UserProfile, UserRoleName } from '../../../../../services/identity/types/UserProfile';
import { getGenderName } from '../../../../../Utils/getGenderName';
import { pronounsOptions } from '../../../../../Utils/selectButtonOptions';
import ReviewProfileHeader from '../../../Components/ReviewProfileHeader';
import ChangeUserStatusModal from '../../../Modal/ChangeUserStatusModal';
import ResetPasswordModal from '../../../Modal/ResetPasswordModal';
import styles from '../../../styles.module.scss';

type ReadOnlyProfileProps = {
  onEditProfile: () => void;
  setImage: React.Dispatch<React.SetStateAction<string | undefined>>;
  image: string;
  thisUser: UserProfile;
  setThisUser: React.Dispatch<React.SetStateAction<UserProfile>>;
  reviewProfile: boolean | undefined;
  id: string | undefined;
  roleName: string;
  onEditDependent: (id: number) => void;
  organizationOptions: Option[];
  clientTagOptions: Option[];
  setDependentId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const ReadOnlyProfile = ({
  onEditProfile,
  setImage,
  image,
  thisUser,
  setThisUser,
  reviewProfile,
  id,
  roleName,
  onEditDependent,
  organizationOptions,
  clientTagOptions,
  setDependentId,
}: ReadOnlyProfileProps): JSX.Element => {
  const { userInfo } = useAppSelector(selectAuth);
  const userRole = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    return userInfo.role.name;
  }, [userInfo]);
  const [resetPasswordConfirmation, setResetPasswordConfirmation] = useState<{ isOpen: boolean; userState: string }>({
    isOpen: false,
    userState: thisUser?.state || '',
  });
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [changeUserStatusFlag, setChangeUserStatusFlag] = useState('');
  const dispatch = useDispatch();
  const disableButton = (): void => {
    setResetEmailSent(true);
  };
  const disableDeleteButton = (): void => {
    setThisUser({
      ...thisUser,
      status: 'Deactivated',
    });
  };
  const selectedGender = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = getGenderName(thisUser?.basic_info.gender);

      if (selectedOption) {
        return selectedOption;
      }

      return thisUser?.basic_info.gender;
    }

    return ' ';
  }, [thisUser]);

  useEffect(() => {
    setDependentId(undefined);
  }, []);

  const displayPhoneNumber = useMemo(() => {
    if (thisUser?.phone_number) {
      const phoneNumber = parsePhoneNumber(thisUser?.phone_number, 'US');
      if (phoneNumber) {
        return phoneNumber.formatNational();
      }
    }

    return '';
  }, [thisUser]);

  const selectedPronouns = useMemo(() => {
    if (thisUser?.basic_info) {
      const selectedOption = pronounsOptions.find((option) => option.value === thisUser?.basic_info.pronouns);

      if (selectedOption) {
        return selectedOption.label;
      }

      return thisUser?.basic_info.pronouns;
    }

    return ' ';
  }, [thisUser]);

  const dateOfBirth = useMemo(() => {
    if (thisUser?.basic_info?.date_of_birth) {
      return moment(thisUser?.basic_info?.date_of_birth, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }
    return ' ';
  }, [thisUser]);

  const languageList = useMemo(() => {
    if (
      thisUser &&
      thisUser?.basic_info &&
      thisUser?.basic_info.languages &&
      thisUser?.basic_info.languages.length > 0
    ) {
      return thisUser?.basic_info.languages
        .map((language: Language | string) => {
          if (typeof language === 'string') {
            return language;
          }
          return language.name;
        })
        .join(', ');
    }
    return ' ';
  }, [thisUser]);

  const fullAddress = useMemo(() => {
    if (thisUser?.address) {
      const { address1, address2, city, state, zip_code: zipCode } = thisUser?.address || {};
      return `${address1}${address2 ? ` , ${address2}` : ''}, ${city}, ${state} ${zipCode}`;
    }

    return ' ';
  }, [thisUser]);

  const userOrganization = useMemo(() => {
    const newOrganizationsArray = thisUser?.organizations?.filter((org) =>
      organizationOptions.some((opt) => opt.id === org.id),
    );
    if (newOrganizationsArray) {
      return newOrganizationsArray
        .map((organization) => {
          return organization.name;
        })
        .join(', ');
    }
    return ' ';
  }, [organizationOptions, thisUser?.organizations]);

  const userClientTag = useMemo(() => {
    const newClientTagsArray =
      thisUser?.markers?.map(
        (marker) => clientTagOptions.find((clientTag) => clientTag.value === marker.value)?.label,
      ) || [];

    return newClientTagsArray.length > 0 ? newClientTagsArray.join(', ') : ' ';
  }, [thisUser, clientTagOptions]);

  const onEditEmailAddress = (): void => {
    dispatch(openModal({ modal: 'updateEmailAddress' }));
  };

  return (
    <div className={styles.readOnlyProfileContainer}>
      {reviewProfile && (
        <ReviewProfileHeader
          thisUser={thisUser}
          setThisUser={setThisUser}
          resetEmailSent={resetEmailSent}
          setChangeUserStatusFlag={setChangeUserStatusFlag}
          setResetPasswordConfirmation={setResetPasswordConfirmation}
          userId={thisUser?.id.toString()}
          userRole={thisUser?.role.name}
          userOrganizations={thisUser?.organizations}
        />
      )}
      <div className={styles.titleContainer}>
        <span className={styles.title}>Profile</span>
        <button
          className={styles.editBtn}
          type="button"
          onClick={() => (roleName !== UserRoleName.Dependent ? onEditProfile() : onEditDependent(thisUser?.id))}
        >
          Edit
          <FiEdit className={styles.editIcon} />
        </button>
      </div>
      {thisUser?.basic_info && (
        <>
          <div className={styles.detailsContainer}>
            <ProfileImage
              reviewProfile={reviewProfile}
              id={id}
              setImage={setImage}
              image={image}
              className={styles.profileImg}
              size="80px"
              isUpload={false}
            />
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Name</div>
              <div className={styles.value}>
                {thisUser?.basic_info?.first_name} {thisUser?.basic_info?.last_name}
              </div>
            </div>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Gender assigned at birth</div>
              <div className={styles.value}>{selectedGender}</div>
            </div>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Pronouns</div>
              <div className={styles.value}>{selectedPronouns}</div>
            </div>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Date of Birth</div>
              <div className={styles.value}>{dateOfBirth}</div>
            </div>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Languages</div>
              <div className={styles.value}>{languageList}</div>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <div className={styles.readOnlyItem}>
              <div className={styles.caption}>Address</div>
              <div className={styles.value}>{fullAddress}</div>
            </div>
          </div>
          {roleName !== UserRoleName.Dependent && (
            <div className={styles.detailsContainer}>
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>
                  Email Address
                  {(!reviewProfile ||
                    (reviewProfile &&
                      userRole !== UserRoleName.Admin &&
                      userRole !== UserRoleName.SuperAdmin &&
                      userRole !== UserRoleName.OrganizationAdmin)) && (
                    <button className={styles.editBtn} type="button" onClick={onEditEmailAddress}>
                      Edit
                      <FiEdit className={styles.editIcon} />
                    </button>
                  )}
                </div>
                <div className={styles.value}>{thisUser?.email}</div>
              </div>
              <div className={styles.readOnlyItem}>
                <div className={styles.caption}>Phone Number</div>
                <div className={styles.value}>{displayPhoneNumber}</div>
              </div>
            </div>
          )}
          {reviewProfile && (
            <>
              <div className={styles.detailsContainer}>
                <div className={styles.readOnlyItem}>
                  <div className={styles.caption}>Organization</div>
                  <div className={styles.value}>{userOrganization}</div>
                </div>
              </div>
              {(userInfo.role.name === UserRoleName.Admin || userInfo.role.name === UserRoleName.SuperAdmin) &&
                thisUser?.markers &&
                thisUser?.markers?.length > 0 && (
                  <div className={styles.detailsContainer}>
                    <div className={styles.readOnlyItem}>
                      <div className={styles.caption}>Client Tag</div>
                      <div className={styles.value}>{userClientTag}</div>
                    </div>
                  </div>
                )}
            </>
          )}
        </>
      )}
      {resetPasswordConfirmation.isOpen && (
        <ResetPasswordModal
          setOpenModal={setResetPasswordConfirmation}
          disableButton={disableButton}
          userId={Number(id)}
          userEmail={thisUser?.email}
          userName={thisUser?.full_name}
          isDeactivated={thisUser?.status === 'Deactivated'}
          userState={resetPasswordConfirmation.userState}
        />
      )}
      {changeUserStatusFlag && (
        <ChangeUserStatusModal
          setOpenModal={setChangeUserStatusFlag}
          disableButton={disableDeleteButton}
          userId={Number(Number(id))}
          userName={thisUser?.full_name}
        />
      )}
    </div>
  );
};

export default ReadOnlyProfile;
