/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react-toastify/dist/ReactToastify.css';

import signInArt from '@brands/Auth/SignIn/utils/assets/signinArt.svg';
import Sidenav from '@brands/Dashboard/Sidenav/Sidenav';
import { getUserByHash } from '@brands/services/auth/getUserByHash';
import { login, LoginResponse } from '@brands/services/auth/login';
import { getStateFromGeocode } from '@brands/services/geolocation/getStateFromGeocode';
import { setUser } from '@brands/store/slices/authSlice';
import { setPatientState } from '@brands/store/slices/currentPatientState';
import { setShowStateModal } from '@brands/store/slices/location';
import { setOrganizationPrices } from '@brands/store/slices/organizationPricingSlice';
import { setOrganization } from '@brands/store/slices/organizationSlice';
import { setShowVideoThumbnail } from '@brands/store/slices/showVideoThumbnailSlice';
import { DEFAULT_PATIENT_LOCATION_STATE, getCurrentPState } from '@brands/Utils/localStorage';
import { stateOptions } from '@brands/Utils/selectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { TbEye } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import Button from '../../Components/Button/Button';
import { config } from '../../config/config';
import { useLocalStorageState } from '../../hooks';
import { useAppDispatch } from '../../hooks/useReduxHook';
import { getCurrentOrganization } from '../../services/identity/getCurrentOrganization';
import { getCurrentProfile } from '../../services/identity/getCurrentProfile';
import { UserProfile, UserRoleName } from '../../services/identity/types/UserProfile';
import { getZendeskUrl } from '../../services/zendesk/getZendeskUrl';
import { displayErrorDetails, ErrorType } from '../../Utils/displayError';
import { getSecondaryColor } from '../../Utils/getPrimaryColor';
import { isValidPhoneNumber } from '../../Utils/validation';
import ConfirmOTP from '../ConfirmOTP/ConfirmOTP';
import ForgotPasswordModalStepOne from '../ForgotPasswordModals/ForgotPasswordModalStepOne';
import ForgotPasswordModalStepThree from '../ForgotPasswordModals/ForgotPasswordModalStepThree';
import ForgotPasswordModalStepTwo from '../ForgotPasswordModals/ForgotPasswordModalStepTwo';
import ForgotPasswordModalSuccess from '../ForgotPasswordModals/ForgotPasswordModalSuccess';
import MultiFactorAuthentication from '../MultiFactorAuthentication/MultiFactorAuthentication';
import styles from './styles.module.scss';
import { validationSchema } from './utils/validationSchema';

type UserSubmitForm = {
  email: string;
  password: string;
};

type ForgotPasswordCredentials = {
  username?: string;
  code: string;
  new_password: string;
  password?: string;
  reset_password_hash?: string;
};

export const JWTContext = createContext('');

type AuthJWT = {
  isAuthenticated: boolean;
};

const SignIn: FC = () => {
  const dispatch = useAppDispatch();
  let phoneNumber = '';
  const [passwordShown, setPasswordShown] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(0);
  const [signInUserNameError, setSignInUserNameError] = useState(false);
  const [signInPasswordError, setSignInPasswordError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [otpModal, setOtpModal] = useState(false);
  const [credentials, setCredentials] = useState<ForgotPasswordCredentials>({
    username: '',
    code: '',
    new_password: '',
  });
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState<{
    trigger: boolean;
    response: LoginResponse | null;
  }>({ trigger: false, response: null });
  const [, setAuthJWT] = useLocalStorageState<AuthJWT>('isAuthenticated', {
    isAuthenticated: false,
  });

  const navigate = useNavigate();
  const getCurrentLocationForPatient = (userInfo: UserProfile): void => {
    if (userInfo.role.name === UserRoleName.Patient) {
      const storedLocationState = getCurrentPState(userInfo.id);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            let currentState = DEFAULT_PATIENT_LOCATION_STATE;
            try {
              const response = await getStateFromGeocode(lat, lng);
              const stateOption = stateOptions.find((option) => option.label.includes(response.state));

              if (stateOption && stateOption !== null) {
                currentState = stateOption.value;
              } else {
                currentState = DEFAULT_PATIENT_LOCATION_STATE;
              }
            } catch (err) {
              currentState = DEFAULT_PATIENT_LOCATION_STATE;
            }

            dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
            dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
          },
          () => {
            const currentState = storedLocationState || DEFAULT_PATIENT_LOCATION_STATE;
            dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
            dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
          },
          { timeout: 10000, enableHighAccuracy: true },
        );
      } else {
        const currentState = storedLocationState || DEFAULT_PATIENT_LOCATION_STATE;
        dispatch(setPatientState({ userId: userInfo.id, state: currentState }));
        dispatch(setShowStateModal({ showStateModal: currentState !== storedLocationState }));
      }
    }
  };

  const goToDashboard = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    navigate(params.get('redirect_uri') || '/dashboard');
  }, [navigate]);

  const goToAdminDashboard = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    navigate(params.get('redirect_uri') || '/admin/dashboard');
  }, [navigate]);

  const handleZendeskSSO = async (): Promise<void> => {
    try {
      const params = new URLSearchParams(window.location.search);
      if (params.get('return_to')) {
        const { url } = await getZendeskUrl(params.get('return_to') || '');
        window.location.href = url;
      }
    } catch {
      console.error('Zendesk is not configured');
    }
  };

  const initialize = async (): Promise<void> => {
    try {
      await handleZendeskSSO();
      const expired = Cookies.get('jwtTokenExpire');
      if (Number(expired) >= Date.now() / 1000) {
        goToDashboard();
        return;
      }
    } catch (error: unknown) {
      displayErrorDetails(error);
    }

    dispatch(setUser({ userInfo: null }));
    dispatch(setShowVideoThumbnail(false));
    dispatch(setOrganization({ currentOrganization: null }));
    dispatch(setOrganizationPrices({ currentOrganizationPrices: null }));
  };

  const togglePassword = (): void => {
    setPasswordShown(!passwordShown);
  };

  const getCurrentLocation = (userInfo: UserProfile): void => {
    if (
      userInfo.role.name === UserRoleName.Admin ||
      userInfo.role.name === UserRoleName.SuperAdmin ||
      userInfo.role.name === UserRoleName.OrganizationAdmin
    ) {
      goToAdminDashboard();
    } else {
      if (userInfo.role.name === UserRoleName.Patient) {
        getCurrentLocationForPatient(userInfo);
      }
      goToDashboard();
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('reset_password_hash')) {
        await getUserByHash(params.get('reset_password_hash') as string)
          .then(() => {
            setResetFlag(true);
            setForgotPasswordStep(3);
            setCredentials({
              reset_password_hash: params.get('reset_password_hash') as string,
              code: '',
              new_password: '',
              password: '',
            });
          })
          .catch((error) => {
            displayErrorDetails(error);
          });
      }
    })();
  }, []);

  const renderForgotPasswordModal = (): JSX.Element | null => {
    switch (forgotPasswordStep) {
      case 1:
        return (
          <ForgotPasswordModalStepOne
            forgotPasswordStep={forgotPasswordStep}
            setForgotPasswordStep={setForgotPasswordStep}
            credentials={credentials}
            setCredentials={setCredentials}
            setOtpModal={setOtpModal}
          />
        );
      case 2:
        return (
          <ForgotPasswordModalStepTwo
            forgotPasswordStep={forgotPasswordStep}
            setForgotPasswordStep={setForgotPasswordStep}
            credentials={credentials}
            setCredentials={setCredentials}
            resetFlag={resetFlag}
          />
        );
      case 3:
        return (
          <ForgotPasswordModalStepThree
            forgotPasswordStep={forgotPasswordStep}
            setForgotPasswordStep={setForgotPasswordStep}
            credentials={credentials}
            setCredentials={setCredentials}
            resetFlag={resetFlag}
            triggerLogin={triggerLogin}
          />
        );
      case 4:
        return (
          <ForgotPasswordModalSuccess
            forgotPasswordStep={forgotPasswordStep}
            setForgotPasswordStep={setForgotPasswordStep}
          />
        );
      default:
        return null;
    }
  };

  const dispatchSuccessEvent = (userId: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login',
      userId,
    });
  };

  const onConfirmedOTP = (): void => {
    setOtpModal(false);
    setLoading(false);
  };

  const triggerLogin = async (response: LoginResponse): Promise<void> => {
    const accessToken = response.access_token;
    const refreshToken = response.refresh_token;

    // Set JWT token as a cookie for the root domain
    Cookies.set('jwtToken', accessToken as string, {
      domain: config.domain_name,
      secure: true,
      sameSite: 'strict',
    });
    Cookies.set('refreshToken', refreshToken as string, {
      domain: config.domain_name,
      secure: true,
      sameSite: 'strict',
    });

    // Need to be removed so that we can handle token expiration in a better way
    Cookies.set('jwtTokenExpire', moment().add(20, 'hours').valueOf().toString(), {
      domain: config.domain_name,
      secure: true,
      sameSite: 'strict',
    });
    await handleZendeskSSO();

    const newJWT: AuthJWT = {
      isAuthenticated: true,
    };
    setAuthJWT(newJWT);
    const userDetails = await getCurrentProfile();
    dispatch(setUser({ userInfo: userDetails }));

    if (
      userDetails.role.name !== UserRoleName.Admin &&
      userDetails.role.name !== UserRoleName.SuperAdmin &&
      userDetails.role.name !== UserRoleName.OrganizationAdmin
    ) {
      const currentOrganization = await getCurrentOrganization();
      dispatch(setOrganization({ currentOrganization }));
    }

    dispatchSuccessEvent(userDetails.id.toString());

    getCurrentLocation(userDetails);
  };

  const endLogin = (data: UserSubmitForm, error: unknown): void => {
    setLoading(false);
    if (error as ErrorType) {
      const { response } = error as ErrorType;
      if (response?.data?.message?.includes('Password reset is required')) {
        setResetFlag(true);
        setCredentials({
          ...credentials,
          username: isValidPhoneNumber(phoneNumber) ? `+1${phoneNumber}` : data.email.toLowerCase(),
        });
        setForgotPasswordStep(2);
        renderForgotPasswordModal();
        return;
      }
      // if (response?.data?.message?.includes('User is not confirmed')) {
      //   setCredentials({
      //     ...credentials,
      //     password: data.password,
      //     username: isValidPhoneNumber(data.email) ? `+1${phoneNumber}` : data.email.toLowerCase(),
      //   });
      //   setOtpModal(true);
      //   return;
      // }
      displayErrorDetails(error);
      if (
        response?.data?.message?.includes('User is not found') ||
        response?.data?.message?.includes('User is not active')
      ) {
        setSignInUserNameError(true);
        setSignInPasswordError(false);
      } else {
        setSignInUserNameError(false);
        setSignInPasswordError(true);
      }
    } else {
      setSignInUserNameError(false);
      setSignInPasswordError(false);
    }
  };

  const onSubmit = async (data: UserSubmitForm): Promise<void> => {
    try {
      setLoading(true);
      if (isValidPhoneNumber(data.email)) {
        phoneNumber = data.email.replace(/\s+|[()-.]/g, '');
      }
      const response = await login({
        email: isValidPhoneNumber(data.email) ? `+1${phoneNumber}` : data.email.toLowerCase(),
        password: data.password,
        organization_id: config.organization_id,
        ...(config.division_id && { division_id: config.division_id }),
        ...(config.practice_id && { practice_id: config.practice_id }),
      });

      if (response.mfa_method) {
        setShowTwoFactorAuth({
          trigger: true,
          response,
        });
      } else if (response.access_token && response.refresh_token) {
        await triggerLogin(response);
      }
    } catch (error: unknown) {
      endLogin(data, error);
    }
  };

  useEffect(() => {
    renderForgotPasswordModal();
  }, [forgotPasswordStep]);

  useEffect(() => {
    initialize();
    localStorage.removeItem('sign-up-form');
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useForm<UserSubmitForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, inputType: 'email' | 'password'): void => {
    clearErrors(inputType);
    const { value } = e.target;
    setSignInUserNameError(false);
    setSignInPasswordError(false);
    setValue(inputType, value);
  };

  if (otpModal) {
    return <ConfirmOTP username={credentials.username as string} onConfirmed={onConfirmedOTP} />;
  }

  return (
    <div>
      {showTwoFactorAuth.trigger ? (
        <MultiFactorAuthentication
          loginResponse={showTwoFactorAuth.response!}
          setShowTwoFactorAuth={setShowTwoFactorAuth}
          userCredentials={{
            email: getValues('email')?.toLowerCase(),
            password: getValues('password'),
          }}
          triggerLogin={triggerLogin}
          endLogin={endLogin}
        />
      ) : (
        <>
          {renderForgotPasswordModal()}
          <Sidenav isVisibleOnMobile hideDefaultOptions>
            <a
              href="https://dentistryone.zendesk.com/hc/en-us/articles/11914427760151-How-to-change-your-password"
              target="_blank"
              className={styles.sidenavContainer}
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <div className={styles.sidenavToggleText}>Help</div>
              <div className={styles.sidenavToggle}>
                <AiOutlineQuestionCircle />
              </div>
            </a>
          </Sidenav>
          <div className={styles.body}>
            <div className={styles.leftSection}>
              <div className={styles.container}>
                <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
                  <span className={styles.formTitle}>
                    <span className={styles.formTitleWelcome}>Welcome back</span>
                    <div className={styles.formTitleLink}>
                      <span className={styles.txt1}>Don't yet have an account?</span>
                      &nbsp;
                      <a
                        className={`txt2 ${styles.txt2}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/sign-up')}
                      >
                        Sign up here.
                      </a>
                    </div>
                  </span>
                  <div className={styles.inputWrap}>
                    <span>Email</span>
                    <input
                      {...register('email')}
                      className={`${styles.input100} ${styles.profileInputClass} ${
                        errors.email || signInUserNameError ? 'is-invalid' : ''
                      }`}
                      type="text"
                      name="email"
                      onChange={(value) => handleOnChange(value, 'email')}
                    />
                    {signInUserNameError ? (
                      <div className={`${styles.invalidFeedback} invalid-feedback`}>Please check your Email!</div>
                    ) : (
                      <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.email?.message}</div>
                    )}
                  </div>
                  <div className={styles.inputWrap}>
                    <span>Password</span>
                    <div className={styles.inputWrapper}>
                      <span className={styles.btnShowPass}>
                        {passwordShown ? (
                          <TbEye
                            className={styles.eyeIcon}
                            style={{ color: '#276fe7' }}
                            title="Hide Password"
                            onClick={togglePassword}
                          />
                        ) : (
                          <TbEye
                            className={styles.eyeIcon}
                            style={{ color: getSecondaryColor() }}
                            title="Show Password"
                            onClick={togglePassword}
                          />
                        )}
                      </span>
                      <input
                        {...register('password')}
                        className={`${styles.input100} ${styles.profileInputClass} ${
                          errors.password || signInPasswordError ? 'is-invalid' : ''
                        }`}
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        onChange={(value) => handleOnChange(value, 'password')}
                        style={{ paddingRight: '24px' }}
                      />
                      {signInPasswordError ? (
                        <div className={`${styles.invalidFeedback} invalid-feedback`}>Please check your Password!</div>
                      ) : (
                        <div className={`${styles.invalidFeedback} invalid-feedback`}>{errors.password?.message}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className={styles.formCheck}
                    style={{ paddingLeft: '0px' }}
                    onClick={() => setForgotPasswordStep(1)}
                  >
                    Forgot password?
                  </div>
                  <Button
                    type="submit"
                    className={`${loading && styles.inActiveBtn}`}
                    style={{ borderRadius: '4px', height: '56px' }}
                  >
                    Login
                  </Button>
                </form>
              </div>
            </div>
            <div className={styles.rightImage}>
              <div
                className={`${styles.cardImage} ${styles.signinArt}`}
                style={{ backgroundImage: `url(${signInArt})` }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignIn;
